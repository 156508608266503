<template>
  <form @submit.prevent="contactData" method="post">
    <div class=" card Contact-info">
      <div class="card-header register-title">
        <h3>{{ fields.tab_title || 'Contact Information' }}</h3>
      </div>
      <div class="card-body pr-4 pl-4">
        <div class="row">
          <div :class="[fields.email.colClass]" v-if="fields.email">
            <div class="form-group">
              <label for="email" class="form-label">{{ fields.email.label }} <sup class="text-danger" v-if="fields.email.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="email" v-model="form.email" name="form.email">
              <span class="text-sm text-danger">{{ errors.email}}</span>
            </div>
          </div>
          <div :class="[fields.retype_email.colClass]" v-if="fields.retype_email">
            <div class="form-group">
              <label for="retype_email" class="form-label">{{ fields.retype_email.label }} <sup class="text-danger" v-if="fields.retype_email.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="retype_email" v-model="form.retype_email" name="form.retype_email">
              <span class="text-sm text-danger">{{ errors.retype_email}}</span>
            </div>
          </div>
          <div :class="[fields.acceptemail.colClass]" v-if="fields.acceptemail">
            <div class="form-group grossrootcheck">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="acceptemail" v-model="form.printed_magazine" name="form.printed_magazine" :value="1">
                <label class="form-check-label font-13" for="acceptemail">{{ fields.acceptemail.label }}</label>
              </div>
            </div>
          </div>
          <div :class="[fields.address_type.colClass]" v-if="fields.address_type">
             <div class="form-group">
                 <label for="address_type" class="form-label">{{ fields.address_type.label }}<sup class="text-danger" v-if="fields.address_type.required">*</sup></label>
                 <select name="form.address_type" id="address_type" class="form-control" v-model="form.address_type" @change="changeAddressType">
                     <option v-for="(item, k) in fields.address_type.options" :key="'address-type-'+k" :value="item.value">{{ item.label }}</option>
                 </select>
             </div>
         </div>
          <div :class="[fields.address1.colClass]" v-if="fields.address1">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.address1.label }} <sup class="text-danger" v-if="fields.address1.required">*</sup><span v-if="fields.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ fields.address1.charLimitText }}</span></label>
              <input type="text" class="form-control" :maxlength=fields.address1.charLimitValue id="address1" v-model="form.address1" name="form.address1">
              <span class="text-sm text-danger">{{ errors.address1}}</span>
            </div>
          </div>
          <div :class="[fields.address2.colClass]" v-if="fields.address2">
            <div class="form-group">
              <label for="firstname" class="form-label">{{ fields.address2.label }}<span v-if="fields.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ fields.address2.charLimitText }} </span></label>
              <input type="text" class="form-control" :maxlength=fields.address2.charLimitValue id="address2" v-model="form.address2" name="form.address2">
              <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div :class="[fields.country.colClass]" v-if="fields.country">
            <div class="form-group">
              <label for="Country" class="form-label">{{ fields.country.label }} <sup class="text-danger" v-if="fields.country.required">*</sup></label>
              <select class="form-control" id="country" @change="removeStateCode" v-model="form.country" name="form.country">
                <option value="">Select</option>
                <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.country}}</span>
            </div>
          </div>
          <div :class="[fields.city.colClass]" v-if="fields.city">
            <div class="form-group">
              <label for="city" class="form-label">{{ fields.city.label }} <sup class="text-danger" v-if="fields.city.required">*</sup></label>
              <input type="text" class="form-control" maxlength="45" id="city"  v-model="form.city" name="form.city">
              <span class="text-sm text-danger">{{ errors.city}}</span>
            </div>
          </div>
          <div :class="[fields.state_code.colClass]" v-if="form.country == 'USA' && fields.state_code">
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state_code" name="form.state_code" >
                <option value="">Select</option>
                <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.state_code}}</span>
            </div>
          </div>
          <div :class="[fields.state_code.colClass]" v-else-if="form.country == 'Canada' && fields.state_code">
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.state_code.label }} <sup class="text-danger" v-if="fields.state_code.required">*</sup></label>
              <select class="form-control" id="state_code" v-model="form.state" name="form.state" >
                <option value="">Select</option>
                <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.state}}</span>
            </div>
          </div>
          <div :class="[fields.other_state.colClass]" v-else>
            <div class="form-group">
              <label for="state_code" class="form-label">{{ fields.other_state.label }} </label>
              <input type="text" class="form-control" maxlength="45" v-model="form.state" name="form.state">
              <span class="text-sm text-danger">{{ errors.state}}</span>
            </div>
          </div>
          <div :class="[fields.zip.colClass]" v-if="fields.zip">
            <div class="form-group">
              <label for="zip" class="form-label">{{ fields.zip.label }} <sup class="text-danger" v-if="fields.zip.required">*</sup></label>
              <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" v-model="form.zip" name="form.zip" >
              <span class="text-sm text-danger">{{ errors.zip}}</span>
            </div>
          </div>
        </div>
        <template v-if="form.address_type == 'Work'">
          <div class="row">
              <div :class="[fields.work_address_company_name.colClass]" v-if="fields.work_address_company_name">
                  <div class="form-group">
                      <label for="work_address_company_name" class="form-label">{{ fields.work_address_company_name.label }}<sup class="text-danger" v-if="fields.work_address_company_name.required">*</sup></label>
                      <input type="text" class="form-control" id="work_address_company_name" v-model="form.company_name">
                      <span class="text-sm text-danger">{{ errors.company_name }}</span>
                  </div>
              </div>
              <div :class="[fields.work_address_company_phone_extension.colClass]" v-if="fields.work_address_company_phone_extension">
                  <div class="form-group">
                      <label for="work_address_company_phone" class="form-label">{{ fields.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="fields.work_address_company_phone_extension.required">*</sup></label>
                      <div class="row">
                          <div class="col-md-9">
                              <vue-tel-input ref="phone" @validate="validateTelinput" v-model="form.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                              <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                          </div>
                          <div class="col-md-3">
                              <input type="text" class="form-control" v-model="form.company_phone_extension" name="form.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                              <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </template>
        <div class="row">
          <div :class="[fields.homephone.colClass]" v-if="fields.homephone">
            <div class="form-group ">
              <div class="phone-nu-wrapper">
                <label for="country_code_h" class="form-label">{{ fields.homephone.label }} <sup class="text-danger" v-if="fields.homephone.required">*</sup></label>
                <vue-tel-input @validate="validateTelinput" v-model="form.phone_1" ref="phone" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                <!-- <input type="text" class="form-control" id="country_code_h" name="country_code_h" placeholder="+1" v-mask="'(###)'">
                 <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1" v-mask="'###-####'">-->
                <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
              </div>
            </div>
          </div>
          <div :class="[fields.mobilephone.colClass]" v-if="fields.mobilephone">
            <div class="form-group">
              <div class="phone-nu-wrapper">
                <label for="phone_2" class="form-label">{{ fields.mobilephone.label }}</label>
                <vue-tel-input @validate="validateTelinput2" v-model="form.phone_2" ref="phone" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1" v-mask="'(###)'">
                <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2" v-mask="'###-####'">-->
                <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-show="overAllError != 0">
          <p class="text-danger text-right">Please fill the above missing fields.</p>
      </div>
      <div class="col-md-12">
        <div class="text-right topspace">
          <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

// import {TheMask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';

const validateName = (name, fieldTxt) => {
  if (name == null || !name.length) {
    return { valid: false, error:  fieldTxt + " is required" };
  }
  return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
      if (!phone.length) {
      return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number.(Example: +XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}
const validatePhone2 = (phone, validTelinput2) => {
  if(validTelinput2 == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number.(Example: +XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}

const validateDob = (dob, fieldTxt) => {
  if (!dob.length) {
    return { valid: false, error: fieldTxt + " is required" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
const validateGender = (gender, fieldTxt) => {
  if (!gender) {
    return { valid: false, error: fieldTxt+ " is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = (select, fieldTxt) => {
  if (select === null || !select.length) {
    return { valid: false, error: fieldTxt + " is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = (email, fieldTxt) => {
  if (!email.length) {
    return { valid: false, error: fieldTxt + " is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};
const validatePassword = password => {
  if (!password.length) {
    return { valid: false, error: "This field is required" };
  }
  if (password.length < 7) {
    return { valid: false, error: "Password is too short" };
  }
  return { valid: true, error: null };
};
const validateZip = (zipCode, country) => {

    if(!zipCode.length)
    {
         return { valid: false, error: "Zip code is required" };
    }
    else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
        return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
    }
    else if(zipCode.length < 5 ){
         return { valid: false, error: "Zip code should be 5 character" };
    }
      return { valid: true, error: null };
}

export default {
  name: "contactinfo",
  components: {VueTelInput},
  props:['formData', 'fields'],
  data() {
    return {
      validTelinput: false,
      phone: "0123456789",
      homeProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Home Number",
        maxLen: 20,
      },
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Mobile Number",
        maxLen: 20,
      },
      companyPhoneExtProps: {
        mode: "international",
        defaultCountry: "US",
        placeholder: "Company Phone Extension",
        // maxLen: 30,
      },
      validTelinput2: false,
      form: {
        "email": '',
        "retype_email": '',
        "printed_magazine" : false,
        'address_type': 'Home',
        "address1": '',
        "address2": '',
        "zip": '',
        "state_code": '',
        "state": '',
        "city": '',
        "country": 'USA',
        "phone_1": '',
        "phone_2": '',
        'company_name': '',
        'company_phone': '',
        'company_phone_extension': ''
      },
      valid: false,
      success: false,
      errors: {},
      message: null,
      overAllError:0

    }
  },
  methods: {
    contactData() {
      if(this.validate()) {
        console.log('valid');
        console.log(this.form);
        this.$emit('contact-data', this.form);        
        return;
      } else {
        console.log('un valid');
      }
    },
    previousStep() {
      this.$emit('previous-step');
      return;
    },
    // Custom Validation Rules
    validate() {
      this.errors = {}
      this.overAllError = 1;
      this.valid = false;
      const validEmail = validateEmail(this.form.email, "E-Mail");
      this.errors.email = validEmail.error;
      
      const validRetypeEmail = validateEmail(this.form.retype_email, "E-mail");
      this.errors.retype_email = validRetypeEmail.error;        

      var validAddress1 = "", validCountry = "", validStateCode = "", validCity = "", validState = "", validZip = "";
      var validCompanyName = "", validCompanyPhone = "", validCompanyPhoneExtension = "";

      validAddress1 = validateName(this.form.address1,"Address");
      this.errors.address1 = validAddress1.error;      

      //const validAddress2 = validateName(this.form.address2);
      // this.errors.address2 = validAddress2.error;

      validCountry = validateSelect(this.form.country,"Country");
      this.errors.country = validCountry.error;      

      validStateCode = validateSelect(this.form.state_code,"State");
      validState = validateName(this.form.state,"State");

      if(validCountry.valid && (this.form.country != 'USA' && this.form.country == 'Canada')) {
        this.errors.state = validState.error;
      } else {
        this.errors.state_code = validStateCode.error;
      }
      validCity = validateName(this.form.city,"City");
      this.errors.city = validCity.error;      

      // validZip = validateName(this.form.zip);
      // this.errors.zip = validZip.error;
       validZip = validateZip(this.form.zip, this.form.country);
      this.errors.zip = validZip.error;      

      if(this.form.address_type == "Work"){
          validCompanyName = validateName(this.form.company_name, "Company Name");
          this.errors.company_name = validCompanyName.error;

          // validCompanyPhone = validatePhone(this.form.company_phone, this.validTelinput);
          // this.errors.company_phone = validCompanyPhone.error;
          
          // validCompanyPhoneExtension = validateName(this.form.company_phone_extension, "Extension");
          // this.errors.company_phone_extension = validCompanyPhoneExtension.error;
      }

      const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput, "Phone Number");
      this.errors.phone_1 = validPhone1.error;
      const validPhone2 = validatePhone2(this.form.phone_2, this.validTelinput2);
      this.errors.phone_2 = validPhone2.error;

      if (validEmail.valid && validRetypeEmail.valid && validAddress1.valid  && validCountry.valid
          && validCity.valid && validZip.valid && validPhone1.valid && validPhone2.valid) {
        this.valid = true;
        this.overAllError = 0;
        if(this.form.address_type == "Work"){
          if(!validCompanyName.valid) this.valid = false;
        }

        if(this.form.email != this.form.retype_email) {
          this.errors.retype_email = 'The email id does not match';
          this.valid = false;
          this.overAllError = 1;
        }
        if(this.form.country == 'USA') {
          this.form.state = '';
          if(!validStateCode.valid) {
            this.valid = false;
             this.overAllError = 1;
          }
        } else if(!validState.valid && this.form.country == 'Canada') {
          this.form.state_code = '';
          this.valid = false;
           this.overAllError = 1;
        }
      }

      return this.valid;
    },
    phoneFormat() {
      let s = this.$refs.phone.value;
      console.log('this='+s);
      var rgx = /^(\+[\d]{1,5}|0)?[7-9]\d{9}$/;
      this.$refs.phone.value = s.match(rgx);
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateTelinput(obj){            
        this.validTelinput = obj.valid;
        if(obj.valid){
            this.errors.phone_1 = '';
        }
    },
    changeAddressType(){
        this.form.address1 = "";
        this.form.address2 = "";
        this.form.country = "";
        this.form.state_code = "";
        this.form.state = "";
        this.form.city = "";
        this.form.zip = "";
        this.form.company_name = "";
        this.form.company_phone = "";
        this.form.company_phone_extension = "";
    },
    removeStateCode(){
      this.form.state = '';
      this.form.state_code = '';
    },
    validateTelinput2(obj){
      this.validTelinput2 = obj.valid;
      if(obj.valid){
        this.errors.phone_2 = '';
      }
    },
  },
  mounted(){
    console.log('contact data')
    console.log(this.form);
    this.form = this.formData;
    this.form.state = '';
  }
}
</script>
