<template>
  <form @submit.prevent="generalInfoData" method="post">
    <div class=" card generalinformation">
      <div class="card-header register-title">
        <h3>{{ fields.tab_title || 'General Information' }}</h3>
      </div>
      <div class="card-body pr-4 pl-4">
        <div class="row" v-if="age > 18 && (fields.description || fields.safe_sport_status)">
          <div class="col-md-12">
            <p class="font-13 text-justify" v-for="(desc,i) in fields.description" :key="'desc'+i" v-html="desc"></p>
            <div class="form-group" v-if="fields.safe_sport_status">
              <label for="sport-trained" class="form-label">{{ fields.safe_sport_status.label }}<sup v-if="fields.safe_sport_status.required">*</sup></label><br>
              <template v-if="fields.safe_sport_status.choices">
                  <template v-for="(choice,k) in fields.safe_sport_status.choices">
                      <input type="radio" :id="'safe-'+choice.value" name="form.safe_sport_status"
                          v-model="form.safe_sport_status" :value="choice.value" @click="safeSports = choice.value" :key="'safe-sport-status-'+k">
                      <label :for="'safe-'+choice.value" :key="'safe-sport-status-label-'+k">{{ choice.label }}</label>
                      <br :key="'safe-sport-status-br'+k">
                  </template>
              </template>

              <span class="text-sm text-danger text-justify">{{ errors.safe_sport_status }}</span>

              <template v-for="(desc,k) in fields.safe_sport_status.no_fields">
                  <span class="font-13 text-danger " v-if="form.safe_sport_status == 3" :key="'safe_sport_status_no' +k">
                      <span class="text-justify" v-html="desc"></span>
                  </span>
              </template>

            </div>


            <div class="row" v-if="fields.safe_sport_status.yes_fields && safeSports == 1 ">
                <div class="col-md-12">
                    <div class="form-group" v-if="fields.safe_sport_status.yes_fields.name">
                        <label for="notsurename" class="form-label font-13">
                            {{ fields.safe_sport_status.yes_fields.name.label }} <sup v-if="fields.safe_sport_status.yes_fields.name.required">*</sup>
                        </label>
                        <input type="text" class="form-control" maxlength="45" id="notsurename"
                                name="form.safe_sport_user_name" v-model="form.safe_sport_user_name">
                        <span class="font-13 text-danger clearfix">{{ errors.safe_sport_user_name }}</span>
                        <span class="text-success font-13">{{ fields.safe_sport_status.yes_fields.name.description }}</span>
                    </div>
                    <div class="form-group" v-if="fields.safe_sport_status.yes_fields.email">
                        <label for="notsuremail" class="form-label">{{ fields.safe_sport_status.yes_fields.email.label }} <sup v-if="fields.safe_sport_status.yes_fields.email.required">*</sup></label>
                        <input type="email" class="form-control" maxlength="45" id="notsuremail"
                                name="form.safe_sport_email" v-model="form.safe_sport_email">
                        <span class="font-13 text-danger clearfix">{{ errors.safe_sport_email }}</span>
                        <span class="text-success font-13">{{ fields.safe_sport_status.yes_fields.email.description }}</span>
                    </div>
                </div>
            </div>
            <!-- <div v-else-if="safeSports == 3">
                <p class="font-13"><i>SafeSports training by <a class="text-danger" target="_blank"
                                                                href="https://safesport.org/authentication/register?token=6a8f3654-bd10-45c4-a2b4-927709856f56">clicking
                    this link</a></i></p>
                <p class="font-13"><strong>Enter Access Code – TVQQ-GZNL-SEBT-INS8</strong></p>
                <p class="font-13"><i>Members have 30 days upon registration to complete the basic or
                    refresher SafeSport training. If training is not completed within 30 days, membership
                    will be suspended and
                    you will be ineligible to compete in any sanctioned USA-WSWS event.</i></p>
            </div> -->


          </div>
        </div>

      <template v-if="fields.sport_discipline">
        <span class="inner-title" v-if="fields.sport_discipline.label"><strong>{{ fields.sport_discipline.label }}</strong></span>
        <p class="font-13 text-justify" v-if="fields.sport_discipline.description" v-html="fields.sport_discipline.description"></p>
        <div class="row">
          <div :class="fields.sport_discipline.first_choice.multiple ? 'col-md-12' : 'col-md-6'" v-if="fields.sport_discipline.first_choice">
            <div class="form-group">
              <label for="first-choice" class="form-label">{{ fields.sport_discipline.first_choice.label }} <sup class="text-danger" v-if="fields.sport_discipline.first_choice.required">*</sup></label>
              <Select2 v-if="fields.sport_discipline.first_choice.multiple" v-model="form.first_choice" 
              name="form.first_choice" id="disciplines" :options="disciplines_list" 
              :settings="{ multiple: true, placeholder: 'Click to select one or multiple', width : 'element'}" 
              @select="collectDisciplinesText" />
              <select v-else class="form-control" id="first-choice" name="form.first_choice"
                      v-model="form.first_choice" @change="updateFirstChoiceTxt">
                <option value="">Select</option>
                <option v-for="(discipline, index) in disciplines" :value="index"
                        :key="'types'+index">{{ discipline }}
                </option>
              </select>
              <span class="text-sm text-danger">{{ errors.first_choice }}</span>
            </div>
          </div>
          <div :class="[fields.sport_discipline.second_choice.colClass]" v-if="fields.sport_discipline.second_choice">
            <div class="form-group">
              <label for="secondchoice" class="form-label">{{ fields.sport_discipline.second_choice.label }} </label>
              <select class="form-control" id="secondchoice" name="form.second_choice"
                      v-model="form.second_choice" @change="updateSecondChoiceTxt">
                <option value="">Select</option>
                <option v-for="(discipline, index) in disciplines" :value="index"
                        :key="'types2-'+index">{{ discipline }}
                </option>
              </select>

              <span class="text-sm text-danger">{{ errors.second_choice }}</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="membership.id != 4">
          <div :class="[fields.sport_discipline.home_federation.colClass]" v-if="fields.sport_discipline.home_federation">
            <div class="form-group">
              <label for="home_federation" class="form-label">{{ fields.sport_discipline.home_federation.label }} <sup v-if="fields.sport_discipline.home_federation.required">*</sup></label>
              <select class="form-control" id="home_federation" name="form.home_federation"
                      v-model="form.home_federation">
                <option value="">Select</option>
                <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
              </select>
              <span class="text-sm text-danger">{{ errors.home_federation }}</span>
            </div>
          </div>
          <div :class="[fields.sport_discipline.skier_license.colClass]" v-if="fields.sport_discipline.skier_license">
            <div v-if="form.home_federation !='USA'">
              <div class="form-group">
                <label for="skier_license" class="form-label">{{ fields.sport_discipline.skier_license.label }}<sup v-if="fields.sport_discipline.skier_license.required">*</sup></label>
                <input type="text" id="skier_license" class="form-control" maxlength="9" @keypress="isNumber($event)"
                       placeholder="" name="form.skier_license"
                       v-model="form.skier_license">
                <span class="text-sm text-danger">{{ errors.skier_license }}</span><br/>
                <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                <div class="text-sm text-success text-justify mt-3" v-html="fields.sport_discipline.skier_license.description"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
      </div>
      <div class="col-md-12" v-show="overAllError != 0">
          <p class="text-danger text-right">Please fill the above missing fields.</p>
      </div>
      <div class="col-md-12">
        <div class="text-right topspace">
          <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous
          </button>
          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
const validateFedration = name => {
  if (!name) {
    return {valid: false, error: "This field is required"};
  } else if (isNaN(name.trim())) {
    return {valid: false, error: "This field must be a numeric value"};
  }
  //  else if (name.length != 9) {
  //   return {valid: false, error: "Foreign federation ID must be 9 characters"};
  // }
  return {valid: true, error: null};
};
const validateName = name => {
  if (!name) {
    return {valid: false, error: "Name is required"};
  }
  return {valid: true, error: null};
};
const validateRadio = (radio, fieldTxt) => {
  if (!radio) {
    return {valid: false, error: fieldTxt + " is required"};
  }
  return {valid: true, error: null};
};
const validateSelect = (select, fieldTxt) => {
  console.log('lenghth=' + select.length);
  console.log('select=' + select);
  if (select == '') {
    return {valid: false, error: fieldTxt + " is required"};
  }
  return {valid: true, error: null};
};
const validateEmail = email => {
    if (!email.length) {
        return {valid: false, error: "Email is required"};
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
        return {valid: false, error: "Please enter a valid email."};
    }
    return {valid: true, error: null};
};

export default {
  name: "generalinfo",
  props: ['disciplines', 'membership',  'formData', 'groups', 'fields', 'age'],
  data() {
    return {
      form: {
        safe_sport_status: '',
        safe_sport_user_name: '',
        safe_sport_email: '',
        first_choice: '',
        first_choice_txt: '',
        second_choice: '',
        second_choice_txt: '',
        home_federation: 'USA',
        skier_license: '',
        disciplines_names: []
      },
      safeSports: null,
      valid: false,
      success: false,
      errors: {},
      message: null,
      checkValue: '',
      checkChoice: {},
      socialSupport: false,
      disciplines_list:[],
      overAllError:0
    }
  },
  methods: {
    generalInfoData() {
      if (this.validate()) {
        console.log('valid');
        if (this.membership.id == 4) {
          this.form.safe_sport_status = 3;
          this.form.home_federation = '';
        }
        this.$emit('general-info-data', this.form);
        return;
      } else {
        console.log('un valid general info');
      }
    },
    previousStep() {
      this.$emit('previous-step');
      return;
    },
    // Custom Validation Rules
    validate() {
      this.errors = {}
      this.valid = false;
      this.overAllError = 1;
      const validSafeSport = validateRadio(this.form.safe_sport_status.toString(), "SafeSport Trained");
      this.errors.safe_sport_status = validSafeSport.error;    

      const validSafeSportUserName = validateName(this.form.safe_sport_user_name);
      this.errors.safe_sport_user_name = validSafeSportUserName.error;

      const validSafeSportEmail = validateEmail(this.form.safe_sport_email);
      this.errors.safe_sport_email = validSafeSportEmail.error;

      let validFirstChoice = validateSelect(this.form.first_choice, "Sport Discipline");
      if(Array.isArray(this.form.first_choice)){
          if(this.form.first_choice.length == 0 || this.form.first_choice ==""){
              validFirstChoice.valid = false;
              validFirstChoice.error = "1st Choice is required";
              this.errors.first_choice = validFirstChoice.error; 
          } else {
              validFirstChoice.valid = true;
              validFirstChoice.error = "";
              this.errors.first_choice = validFirstChoice.error;            
          }
      } else {
          this.errors.first_choice = validFirstChoice.error;       
      }

      const validSafeSportForeignFederationId = validateFedration(this.form.skier_license);
      this.errors.skier_license = validSafeSportForeignFederationId.error;

      const validFederation = validateSelect(this.form.home_federation,"Federation");
      this.errors.home_federation = validFederation.error;      

      if(document.getElementById("home_federation") == null) validFederation.valid = true;

      if (this.membership.id == 4) {
        validSafeSport.valid = true;
        this.socialSupport = true;
        validFederation.valid = true;
      }
      if(document.querySelector("[name='form.safe_sport_status']") != null){
        if (validSafeSport.valid && validFirstChoice.valid && validFederation.valid) {
          this.valid = true;
          this.overAllError = 0;
          if (this.socialSupport == false && (this.safeSports == 1 && (!validSafeSportUserName.valid || !validSafeSportEmail.valid))) {
            this.valid = false;
          } else if (this.safeSports != 1) {
            this.form.safe_sport_user_name = '';
            this.form.safe_sport_email = '';
            this.valid = true;
            this.overAllError = 0;            
          } else {
            this.valid = true;
            this.overAllError = 0;
          }

          if (this.socialSupport == false && this.form.home_federation != 'USA' && !validSafeSportForeignFederationId.valid) {
            this.valid = false;
            this.overAllError = 1;
          } else if (this.form.home_federation == 'USA') {
            this.form.skier_license = '';
            this.valid = true;
            this.overAllError = 0;
          } else {
            this.valid = true;
            this.overAllError = 0;
          }
        }
      } else if(validFirstChoice.valid) {
          this.valid = true;
          this.overAllError = 0;
          this.form.safe_sport_status = 0;
      }

      return this.valid;
    },
    collectDisciplinesText(e){
      if(this.form.disciplines_names.length)
        this.form.disciplines_names = this.form.disciplines_names.filter(item => item.id != e.id);

      if(e.selected){
        this.form.disciplines_names.push({ id: e.id, name: e.text });
      }
    },
    updateFirstChoiceTxt(e){
      this.form.first_choice_txt = this.disciplines[e.target.value];
    },
    updateSecondChoiceTxt(e){
      this.form.second_choice_txt = this.disciplines[e.target.value];
    }
  },
  mounted() {
      // console.log('general info data');
      // console.log(this.groups);

      const retry = setInterval(() => {
          if(this.formData.member_type_id){
            this.form = {
              safe_sport_status: this.formData.safe_sport_status == "" ? this.formData.safe_sport_status : "",
              safe_sport_user_name: this.formData.safe_sport_user_name || '',
              safe_sport_email: this.formData.safe_sport_email || '',
              first_choice: this.formData.first_choice || '',
              first_choice_txt: this.formData.members[0].first_choice_txt || '',
              second_choice: this.formData.members[0].second_choice_txt != "" ? this.formData.members[0].second_choice : '',
              second_choice_txt: this.formData.members[0].second_choice_txt || '',
              home_federation: this.formData.members[0].home_federation || 'USA',
              skier_license: this.formData.members[0].skier_license || '',
            };
            if(Array.isArray(this.formData.first_choice)){
              let names = [];
              this.formData.first_choice.forEach((id, index) => {
                if(this.disciplines[id] != undefined){
                  names.push({ id: id, name: this.disciplines[id] });
                }
              });
              this.form.disciplines_names = names;
            }
            clearInterval(retry);
          }
      }, 200);
  },
  updated() {
      // this.disciplines_list = this.disciplines.m
      let discipline_codes = Object.keys(this.disciplines);
      if(discipline_codes.length && this.disciplines_list.length == 0){
          discipline_codes.forEach(code => {
              this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
          });
      }
  }
}
</script>
