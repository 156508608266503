<template>
  <div class="merchandise">
    <template v-if="merchandise_hide == 'hide'">
      <div class="card-header register-title merchandise_hide">
        <h3>Merchandise</h3>
        <img src="/images/com-soon5.svg"  alt="wtc-banner">
      </div>
    </template>
    <template v-else>
      <div>
        <div class="card-header register-title">
          <h3>Merchandise</h3>
          <p class="mb-0 text-justify" >USA-WSWS members receive 10% off all merchandise items. Prices on the below items already include the 10% discount. Shipping is $7.95 for 1-2 items, $9.75 for 3-5 items and $12.25 for 6 or more items.</p>
          <br>
          <p class="mb-0 text-justify">Unfortunately, international orders are not accepted. Merchandise is only shipped within the U.S. and Canada.</p>
        </div>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-1 variant="info">Men’s/Unisex Apparel</b-button>
            </b-card-header>
            <b-collapse id="accordion-1"  accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <ul class="cart-items">
                    <li  v-for="(item, x) in merchandiseDataMen" :key="x">
                      <div class="row">
                        <template v-for="(image, a) in item.images">
                          <div class="col-md-2" :key="a" v-if="styleimages[addingtocartMenColor[x]] && image.variant_list_id == styleimages[addingtocartMenColor[x]]">
                            <enlargeable-image v-bind:src="'/dist/img/merchandise/products/'+image.url+''" v-bind:src_large="'/dist/img/merchandise/products/'+image.url+''" />
                          </div>
                          <div class="col-md-2 " :key="a" v-else-if= "image.favorite_image == 1 && !styleimages[addingtocartMenColor[x]]">
                            <enlargeable-image v-bind:src="'/dist/img/merchandise/products/'+image.url+''" v-bind:src_large="'/dist/img/merchandise/products/'+image.url+''" />
                          </div>
                        </template>
                        <div class="col-md-6 pl-0 mp-l-r">
                          <h5>{{ item.name }}</h5>
                          <input type="hidden"  v-model="item.id" :ref="'ref-product_id-'+item.id">
                          <input type="hidden"  value="1" :ref="'ref-is_variant-'+item.id">
                          <div class="d-flex">
                            <div class="price" :key="pricerefresh">
                              <span>$<span class="text-danger"><strong>{{ (parseFloat(item.price) + parseFloat(merchandiseDataMenprice[x])).toFixed(2) }}</strong></span></span>
                            </div>
                            <!-- <div class="shipping">
                                <span><img  v-bind:src="'/dist/img/memberregistration/shipping.png'" alt="shipping" />Free Shipping</span>
                            </div> -->
                          </div>
                          <div v-for="(main_varinats_size, y) in item.variants" :key="y">
                            <div v-if= "main_varinats_size.name == 'Size'" class="d-flex form-group size-picker sizelabel">
                              <label for="select_men_size" class="form-label">size</label>
                              <select class="form-control" id="select_men_size" @change="merchandiseDataMenOnChange($event,main_varinats_size.variants,x)" :ref="'addingtocartMen_ref-variant_list_size_id-'+item.id">
                                <option v-for="(size_varinats, z) in main_varinats_size.variants" :key="z" :value="size_varinats.id">{{ size_varinats.name }}</option>
                              </select>
                            </div>
                          </div>
                          <div v-for="(main_varinats_size, b) in item.variants" :key="'A'+b">
                            <div v-if= "main_varinats_size.name == 'Color'&&isShowLogo" class="d-flex size-picker">
                              <label for="select_access_size" class="form-label">Logo</label>
                              <select class="form-control" id="select_style_id" @change="styleChange($event,addingtocartMenColor[x])" v-model="addingtocartMenColor[x]" :ref="'addingtocartAccessories_ref-variant_list_size_id-'+item.id">
                                <option v-for="(color_varinats, p) in main_varinats_size.variants" :selected="p == 0" :key="'B'+p" :value="color_varinats.id">{{ color_varinats.name }}</option>
                              </select>
                            </div>
                          </div>
                          <!-- <div v-for="(main_varinats_size, b) in item.variants" :key="'A'+b">
                              <ul class="colorcombination" v-if= "main_varinats_size.name == 'Color'">
                                       <li v-for="(color_varinats, p) in main_varinats_size.variants" :key="'B'+p">
                                           <input :id="color_varinats.id" type="radio" v-model="addingtocartMenColor[x]" :checked="p == 0"  :value="color_varinats.id">
                                           <label :class="color_varinats.name" :for="color_varinats.id"></label>
                                       </li>


                              </ul>
                          </div> -->
                          <div class="d-flex btn-and-quantiy mt-3">
                            <div class="form-group quantitypicker">
                              <input  class="form-control input_align" type="number" min="1" v-model="addingtocartMenQuantity[x]" :ref="'ref-quantity-'+item.id">
                            </div>
                            <div class="form-group">
                              <button :disabled="isActive[item.id]" @click.once="addToCart(item.id,'addingtocartMen',x)"
                                      aria-expanded="false" type="button" class="btn btn-primary addtocart"
                                      :ref="'ref-add-to-cart-'+item.id"
                                      :key="btnClickedKey +1"
                              >Add to cart</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-2 variant="info">Women’s Apparel</b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <ul class="cart-items">
                    <li  v-for="(itemw, d) in merchandiseDataWomen" :key="d">
                      <div class="row">
                        <template v-for="(image, o) in itemw.images">
                          <div class="col-md-2" :key="o" v-if="womenstyleimages[addingtocartWoMenColor[d]] && image.variant_list_id == womenstyleimages[addingtocartWoMenColor[d]]">
                            <enlargeable-image v-bind:src="'/dist/img/merchandise/products/'+image.url+''" v-bind:src_large="'/dist/img/merchandise/products/'+image.url+''" />
                          </div>
                          <div class="col-md-2" :key="o" v-if="image.favorite_image == 1 && !womenstyleimages[addingtocartWoMenColor[d]]">
                            <enlargeable-image v-bind:src="'/dist/img/merchandise/products/'+image.url+''" v-bind:src_large="'/dist/img/merchandise/products/'+image.url+''" />
                          </div>
                        </template>
                        <div class="col-md-6 pl-0">
                          <h5>{{ itemw.name }}</h5>
                          <input type="hidden"  v-model="itemw.id" :ref="'ref-product_id-'+itemw.id">
                          <input type="hidden"  value="1" :ref="'ref-is_variant-'+itemw.id">
                          <div class="d-flex">
                            <div class="price" :key="pricerefresh">
                              <span>$<span class="text-danger"><strong>{{ (parseFloat(itemw.price)+parseFloat(merchandiseDataWomenprice[d])).toFixed(2) }}</strong></span></span>
                            </div>
                          </div>
                          <div v-for="(main_varinats, e) in itemw.variants" :key="e">
                            <div v-if= "main_varinats.name == 'Size'" class="d-flex form-group size-picker">
                              <label for="select_women_size" class="form-label">size</label>
                              <select class="form-control" id="select_women_size" @change="merchandiseDataWomenOnChange($event,main_varinats.variants,d)" :ref="'addingtocartWoMen_ref-variant_list_size_id-'+itemw.id">
                                <option v-for="(size_varinats, f) in main_varinats.variants" :key="f" :value="size_varinats.id">{{ size_varinats.name }}</option>
                              </select>
                            </div>
                          </div>
                          <!-- <div v-for="(main_varinats, g) in itemw.variants" :key="'C'+g">
                              <ul class="colorcombination" v-if= "main_varinats.name == 'Color'">
                                  <li v-for="(color_varinats, h) in main_varinats.variants" :key="'D'+h">
                                      <input :id="color_varinats.id" type="radio" v-model="addingtocartWoMenColor[d]" :checked="h == 0" :value="color_varinats.id">
                                      <label :class="color_varinats.name" :for="color_varinats.id"></label>
                                  </li>
                              </ul>
                          </div> -->
                          <div v-for="(main_varinats, g) in itemw.variants" :key="'C'+g">
                            <div v-if= "main_varinats.name == 'Color' && isShowLogo" class="d-flex size-picker">
                              <label for="select_access_size" class="form-label">Logo</label>
                              <select class="form-control" id="color_varinats_women" @change="womenstylechange($event,addingtocartWoMenColor[d])" v-model="addingtocartWoMenColor[d]" :ref="'addingtocartAccessories_ref-variant_list_size_id-'+itemw.id">
                                <option v-for="(color_varinats, h) in main_varinats.variants" :key="h" :selected="h == 0" :value="color_varinats.id">{{ color_varinats.name }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="d-flex btn-and-quantiy mt-3">
                            <div class="form-group quantitypicker">
                              <input  class="form-control input_align" type="number" min="1" v-model="addingtocartWoMenQuantity[d]" :ref="'ref-quantity-'+itemw.id">
                            </div>
                            <div class="form-group">
                              <button :disabled="isActive[itemw.id]" @click="addToCart(itemw.id, 'addingtocartWoMen',d)" type="button" class="btn btn-primary addtocart" :ref="'ref-add-to-cart-'+itemw.id">Add to cart</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1" v-if="subdomain == 'waterski'">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-3 variant="info">Accessories</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <ul class="cart-items">
                    <li  v-for="(itema, i) in merchandiseDataAccessories" :key="i">
                      <div class="row">
                        <template v-for="(image, j) in itema.images">
                          <div class="col-md-2" :key="j" v-if= "image.favorite_image == 1">
                            <enlargeable-image v-bind:src="'/dist/img/merchandise/products/'+image.url+''" v-bind:src_large="'/dist/img/merchandise/products/'+image.url+''" />
                          </div>
                        </template>
                        <div class="col-md-6 pl-0">
                          <h5>{{ itema.name }}</h5>
                          <input type="hidden"  v-model="itema.id" :ref="'ref-product_id-'+itema.id">
                          <input type="hidden" v-if="itema.variants.length" value="1" :ref="'ref-is_variant-'+itema.id">
                          <input type="hidden" v-else value="1" :ref="'ref-is_variant-'+itema.id">
                          <div class="d-flex">
                            <div class="price">
                              <span>$<span class="text-danger"><strong>{{ itema.price }}</strong></span></span>
                            </div>
                            <!-- <div class="shipping">
                                <span><img  v-bind:src="'/dist/img/memberregistration/shipping.png'" alt="shipping" />Free Shipping</span>
                            </div> -->
                          </div>
                          <div v-for="(main_varinats, k) in itema.variants" :key="k">
                            <div v-if= "main_varinats.name == 'Size'" class="d-flex form-group size-picker">
                              <label for="select_access_size" class="form-label">size</label>
                              <select class="form-control" id="select_access_size" :ref="'addingtocartAccessories_ref-variant_list_size_id-'+itema.id">
                                <option v-for="(size_varinats, l) in main_varinats.variants" :key="l" :value="size_varinats.id">{{ size_varinats.name }}</option>
                              </select>
                            </div>
                          </div>
                          <!-- <div v-for="(main_varinats, m) in itema.variants" :key="'E'+m">
                              <ul class="colorcombination" v-if= "main_varinats.name == 'Color'">
                                  <li v-for="(color_varinats, n) in main_varinats.variants" :key="'F'+n">
                                      <input :id="color_varinats.id" type="radio" v-model="addingtocartAccessoriesColor[i]" :checked="n == 0" :value="color_varinats.id">
                                      <label :class="color_varinats.name" :for="color_varinats.id"></label>
                                  </li>
                              </ul>
                          </div> -->

                          <!-- <div v-for="(main_varinats, m) in itema.variants" :key="'E'+m">
                            <div v-if= "main_varinats.name == 'Color'" class="d-flex size-picker">
                              <label for="select_access_size" class="form-label">Style</label>
                              <select class="form-control" id="select_accessories_id" v-model="addingtocartAccessoriesColor[i]" :ref="'addingtocartAccessories_ref-variant_list_size_id-'+itema.id">
                                      <option v-for="(color_varinats, n) in main_varinats.variants" :key="n" :selected="n == 0" :value="color_varinats.id">{{ color_varinats.name }}</option>
                              </select>
                            </div>
                          </div>                               -->
                          <div class="d-flex btn-and-quantiy mt-3">
                            <div class="form-group quantitypicker">
                              <input  class="form-control input_align" type="number" min="1" v-model="addingtocartAccessoriesQuantity[i]" :ref="'ref-quantity-'+itema.id">
                            </div>
                            <div class="form-group">
                              <button :disabled="isActive[itema.id]" @click="addToCart(itema.id, 'addingtocartAccessories',i)" type="button" class="btn btn-primary addtocart addtocart" :ref="'ref-add-to-cart-'+itema.id">Add to cart</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </template>

    <div class="col-md-12 pr-4 pl-4 pt-3  " v-if="cartProductData.length >= 1">
      <!-- <hr> -->
      <h4>Cart</h4>
      <ul  class="pl-1 pr-1 cartulitem"  v-if="cartProductData.length >= 1">
        <hr>
        <li v-for="(cartProduct, c) in cartProductData" class="d-flex justify-content-between lh-sm" :key="c">
          <div class="cartitemcard">
            <span class="remove" @click="removeFromCart(cartProduct.id)"><i class="fas fa-trash-alt"></i></span>
            <h6 class="my-0 font-13">{{ cartProduct.name+' '+cartProduct.variant_name  }}  ${{cartProduct.price}} x {{ cartProduct.quantity }}</h6>
          </div>
          <span class="text-muted font-13"><strong>${{ cartProduct.total }}</strong></span>
        </li>
        <hr v-if="cartProductData.length >= 1">
        <li v-if="cartProductData.length >= 1" class="d-flex justify-content-between lh-sm">
          <div>
            <h6 class="my-0 font-13"><strong>Merchandise Total</strong></h6>
          </div>
          <span class="text-muted font-13"><strong>${{ total }}</strong></span>
        </li>
        <hr v-if="cartProductData.length >= 1">
        <li v-if="cartProductData.length >= 1" class="d-flex justify-content-between lh-sm mb-1">
          <div>
            <h6 class="my-0 font-13">Shipping Charge - {{ cartLength }} Item(s)</h6>
          </div>
          <span class="text-muted font-13"><strong>${{ shippingTotal }}</strong></span>
        </li>
      </ul>
    </div>
    <div class="col-md-12">
      <form @submit.prevent="merchandiseCapture" method="post">
        <div class="text-right topspace">
          <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn">{{ buttonMain.nextButton }}<i class="fas fa-chevron-right pl-2"></i></button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import axios from 'axios';
export default {
  name: "Merchandise",
  components: {
    EnlargeableImage
  },
  props: ['merchandiseDataMen','merchandiseDataWomen','merchandiseDataAccessories', 'regId', 'buttonMain', 'fields'],
  data() {
    return {
      //merchandiseDataProduct: [],
      is_first_time: true,
      addedToCart: 0,
      isShowLogo:false,
      quantityDefault: 1,
      merchandiseDataMenprice:[0,0,0,0,0,0,0,0,0,0,0,0],
      merchandiseDataWomenprice:[0,0,0,0,0,0,0,0,0,0,0,0],
      pricerefresh:0,
      addingtocartMenQuantity: [1,1,1,1,1,1,1,1,1,1,1],
      addingtocartWoMenQuantity: [1,1,1,1,1],
      addingtocartAccessoriesQuantity: [1,1,1,1,1],
      merchandise : {'product_id':'',variant_list_id:[]},
      baseShipping: 4.99,
      cartProductData: [],
      addingtocartMenColor:[1,12,20,27],
      addingtocartWoMenColor:[35],
      addingtocartAccessoriesColor:[42,66,111],
      styleimages:[],
      womenstyleimages:[],
      button: {
        addingtocartMen: [],
        addingtocartWoMen: [],
        addingtocartAccessories: [],
        currentColor: ''
      },
      btnClickedKey:0,
      totals: {
        merchandise_total: 0.00,
        sub_total: 0.00,
        grand_total: 0.00,
      },
      isActive: [false,false,false,false,false,false,false,false,false,false,false],
      varientImage:[]
    }
  },
  methods: {
    merchandiseDataMenOnChange(event,size_varinats,x) {
      for(let i=0; i<size_varinats.length;i++)
      {
        if(size_varinats[i].id == event.target.value)
        {
          this.merchandiseDataMenprice[x] = size_varinats[i].price;
          this.pricerefresh++;
        }
      }
    },
    merchandiseDataWomenOnChange(event,size_varinats,x){
      for(let i=0; i<size_varinats.length;i++)
      {
        if(size_varinats[i].id == event.target.value)
        {
          this.merchandiseDataWomenprice[x] = size_varinats[i].price;
          this.pricerefresh++;
        }
      }
    },
    addToCart: function(index, event,key) {
      this.button[event][key] = false;
      this.isActive[index] = true;
      this.pricerefresh++;
      this.merchandise.product_id = (this.$refs['ref-product_id-'+index][0]['attributes']['value']['value']);
      this.merchandise.is_variant = (this.$refs['ref-is_variant-'+index][0]['attributes']['value']['value']);
      this.merchandise.quantity = this[event+'Quantity'][key];//(this.$refs['ref-quantity-'+index][0]['attributes']['value']['value']);
      /*console.log('------1------');
      console.log(this.$refs);
      console.log('------------');
      console.log(this.$refs['ref-quantity-'+index]);
      console.log('------+++------');
      console.log(this.$refs['ref-quantity-'+index].value);
      console.log('idx'+ index);*/
      if(this.$refs[event + '_ref-variant_list_size_id-' + index]) {
        this.merchandise.variant_list_id.push((this.$refs[event + '_ref-variant_list_size_id-' + index]) ? this.$refs[event + '_ref-variant_list_size_id-' + index][0]['options'][this.$refs[event + '_ref-variant_list_size_id-' + index][0]['options']['selectedIndex']]['value'] : '');
      }
      console.log('================================');
      console.log('idx'+ key);
      /*console.log(this.$refs[event + '_ref-variant_list_color_id-' + index]);
      console.log(this.$refs[event + '_ref-variant_list_color_id-' + index][0]['options']);
      console.log(this.$refs[event + '_ref-variant_list_color_id-' + index][0]['options'][this.$refs[event + '_ref-variant_list_color_id-' + index][0]['options']['selectedIndex']]['value']);*/
      if(this[event+'Color'][key]) {
        this.merchandise.variant_list_id.push(this[event+'Color'][key]);
      }
        this.merchandise.registration_id = this.regId;
        this.merchandise.isRenew = 1;
        this.merchandise.is_first_time = this.is_first_time;
      console.log('ssssssssssssss11');
      axios.post(this.basePath+'api/merchandise_add_to_cart', this.merchandise)
          .then((res) => {
            this.btnClickedKey++;
            this.addedToCart = 1;
            console.log('rrrrrrrrrrrrrr');
            console.log(res.data.data);
            this.cartProductData = res.data.data;
            this.$emit('cart-products', res.data.data);
            if(this.is_first_time) this.is_first_time = false;
            return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            return false;
          }).finally(() => {
        this.merchandise = {'product_id':'',variant_list_id:[]};
        this.button[event][key] = true;
        this.isActive[index] = false;
        this.pricerefresh++;
      });
    },
    previousStep() {
      this.$emit('previous-step');
      return;
    },
    merchandiseCapture() {
      this.$emit('added-to-cart', this.addedToCart);
    },
    selectedColor(ref) {
      this.currentColor = this.$refs[ref];
      console.log('current color='+this.currentColor);
    },
    removeFromCart(cart_id) {
      let cartData = {};
      cartData.cart_id = cart_id;
      cartData.registration_id = this.regId;
      cartData.isRenew = 1;
      console.log('---------------');
      console.log(cartData);
      axios.post(this.basePath+'api/merchandise_remove_from_cart', cartData)
          .then((res) => {
            if(res.data.status == 'success') {
              this.cartProductData = res.data.data;
              console.log('cart--length--' + this.cartProductData.length);
              console.log('addedToCart----' + this.addedToCart);
              this.placeOrderCalculation();
            }
            return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    addingToCartMenColorFunc (type,variable, buttons, quantity) {
      type.forEach((value,index) => {
        variable[index] = value.default_color.id;
        buttons[index] = true;
        quantity[index] = 1;
      });
      console.log('-----------variable----------');
      console.log(variable);
    },
    placeOrderCalculation() {
      this.$emit('cart-products',this.cartProductData);
      this.$emit('placeOrderCalculation');
      return;
    },
    styleChange(event,value) {
      this.styleimages[value] = event.target.value;
    },
    womenstylechange(event,value){
      this.womenstyleimages[value] = event.target.value;
    }
  },
  mounted(){
    let cartButtons = this.button;
    let retryM = setInterval(() => {
      this.addingToCartMenColorFunc(this.merchandiseDataMen, this.addingtocartMenColor, cartButtons.addingtocartMen, this.addingtocartMenQuantity);
      if(this.addingtocartMenColor["0"] != '') {
        clearInterval(retryM);
      }
    }, 200);
    let retryW = setInterval(() => {
      this.addingToCartMenColorFunc(this.merchandiseDataWomen, this.addingtocartWoMenColor, cartButtons.addingtocartWoMen, this.addingtocartWoMenQuantity);
      if(this.addingtocartWoMenColor["0"] != '') {
        clearInterval(retryW);
      }
    }, 200);
    let retryA = setInterval(() => {
      this.addingToCartMenColorFunc(this.merchandiseDataAccessories, this.addingtocartAccessoriesColor, cartButtons.addingtocartAccessories, this.addingtocartAccessoriesQuantity);
      if(this.addingtocartAccessoriesColor["0"] != '') {
        clearInterval(retryA);
      }
    }, 200);
  },
  computed: {
    merchandise_hide: function(){
      return process.env.VUE_APP_MERCHANDISE;
    },
    total: function(){
      return this.cartProductData.reduce(function(total, item){
        console.log(total +'---total---'+ item.total+'======='+parseFloat(parseFloat(total) + parseFloat(item.total)).toFixed(2));
        return parseFloat(parseFloat(total) + parseFloat(item.total)).toFixed(2);
      },0);
    },
    cartLength: function(){
      return this.cartProductData.reduce(function(quantity, item){
        //console.log(item.quantity+'--'+item.product_id+'='+quantity);
        return quantity + item.quantity;
      },0);
    },
    shippingCosts: function () {
      return this.getSiteProps('merchandise_shipping');
    },
    shippingTotal: function(){
      let shippingCost = 0.00;
      if(this.shippingCosts.shipping_above_six_item != undefined && this.shippingCosts.shipping_four_to_six_item != undefined && this.shippingCosts.shipping_first_three_item != undefined){
        if(this.cartLength >= 6) {
          shippingCost =  parseFloat(this.shippingCosts.shipping_above_six_item).toFixed(2);
        } else if(this.cartLength <= 5 && this.cartLength >= 3) {
          shippingCost =  parseFloat(this.shippingCosts.shipping_four_to_six_item).toFixed(2);
        } else if(this.cartLength <= 2 ) {
          shippingCost =  parseFloat(this.shippingCosts.shipping_first_three_item).toFixed(2);
        }
      }
      return shippingCost;
    },
    /*additionalShipping: function(){
      console.log(this.cartLength+"===00000000000000000");
      return parseFloat((this.cartLength -1)* 1.99).toFixed(2);
    }*/
  }
}
</script>
<style>
.merchandise_hide img {
  width: 100%;
  max-width: 30%;
  margin: 40px auto 20px auto !important;
  display: block;
}
.merchandise img{
  padding:0px !important;
}
.input_align{
  padding-right: 0px !important;
}

</style>