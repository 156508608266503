<template>
  <form @submit.prevent="personalData" method="post">
    <div class="card personal-type">
      <div class="card-header register-title">
        <!-- <h3 v-if="membership.id == 5">Head of Household Information</h3>
        <h3 v-else>Personal Information</h3> -->
        <h3>{{ fields.tab_title || 'Personal Information' }}</h3>
      </div>

      <div class="card-body pr-4 pl-4">
        <div class="row mb-2" v-if="fields.sub_title">
            <div class="col-md-12 text-center">
                <h5 class="tab_subtitle">{{ fields.sub_title || '' }}</h5>
            </div>
        </div>
        <div class="row">
          <div :class="[fields.title.colClass]" v-if="fields.title">
              <div class="form-group">
                  <label for="title" class="form-label">{{ fields.title.label }}<sup class="text-danger" v-if="fields.title.required">*</sup></label>
                  <!-- <select name="form.title" id="title" class="form-control" v-model="form.title">                  
                      <option v-for="(item, k) in fields.title.options" :value="item" :key="'title-'+k" >{{ item }}</option>
                  </select> -->
                  <input type="text" class="form-control" :placeholder="fields.title.placeholder" id="title" v-model="form.title" name="form.title" value="">
                  <span class="text-sm text-danger">{{ errors.title }}</span>
              </div>
          </div>
          <div :class="[fields.firstname.colClass]" v-if="fields.firstname">
            <div class="form-group">
              <label for="firstName" class="form-label">{{ fields.firstname.label }}<sup class="text-danger" v-if="fields.firstname.required">*</sup></label>
              <input type="text" class="form-control" :placeholder="fields.firstname.placeholder" maxlength="45" id="firstName" v-model="form.first_name" name="form.first_name" value="">
              <span class="text-sm text-danger">{{ errors.first_name }}</span>
            </div>
          </div>
          <div :class="[fields.middlename.colClass]" v-if="fields.middlename">
            <div class="form-group">
              <label for="middlename" class="form-label">{{ fields.middlename.label }}<sup class="text-danger" v-if="fields.middlename.required">*</sup></label>
              <input type="text" class="form-control" :placeholder="fields.middlename.placeholder" maxlength="3" id="middlename" v-model="form.middle_name" name="form.middle_name"
                     value="">
            </div>
          </div>
          <div :class="[fields.lastname.colClass]" v-if="fields.lastname">
            <div class="form-group">
              <label for="lastname" class="form-label">{{ fields.lastname.label }}<sup class="text-danger" v-if="fields.lastname.required">*</sup></label>
              <input type="text" class="form-control" :placeholder="fields.lastname.placeholder" maxlength="45" id="lastname" v-model="form.last_name" name="form.last_name" value="">
              <span class="text-sm text-danger">{{ errors.last_name}}</span>
            </div>
          </div>
          <div :class="[fields.suffix.colClass]" v-if="fields.suffix">
            <div class="form-group">
              <label for="suffix" class="form-label">{{ fields.suffix.label }}<sup class="text-danger" v-if="fields.suffix.required">*</sup></label>
              <input type="text" class="form-control" :placeholder="fields.suffix.placeholder" maxlength="45" id="suffix" name="suffix" value="" v-model="form.suffix">
              <span class="text-sm text-danger">{{ errors.suffix}}</span>
            </div>
          </div>

          <div :class="[fields.clubandteam.colClass]" v-if="fields.clubandteam">
            <div class="form-group">
              <label for="clubandteam" class="form-label">{{ fields.clubandteam.label }}<sup class="text-danger" v-if="fields.clubandteam.required">*</sup></label>
              <Select2 v-model="form.affiliate" :options="options" :placeholder="fields.clubandteam.placeholder"  :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
              <p class="mt-2 font-13" v-if="fields.clubandteam.description"><i v-html="fields.clubandteam.description"></i></p>
            </div>
          </div>
          <div :class="[fields.birthday.colClass]" v-if="fields.birthday">
            <div class="form-group">
              <label for="birthday" class="form-label">{{ fields.birthday.label }} <sup class="text-danger" v-if="fields.birthday.required">*</sup></label>
              <div class="input-group birthdate">
                <div class="birthdaypicker">
                  <date-pick
                      v-model="form.dob"
                      :pickTime="false"
                      name="form.dob"
                      :format="'MM/DD/YYYY'"
                      :selectableYearRange="{from: 1900, to: 2021}"
                      v-mask="'##/##/####'"
                      :isDateDisabled="isFutureDate"
                     :inputAttributes="{placeholder: 'mm/dd/yyyy'}"
                      :parseDate="parseDatePick"
                  ></date-pick>
                </div>
                <span class="text-sm text-danger">{{ errors.dob}}</span>

              </div>
            </div>
          </div>

          <div :class="[fields.gender.colClass]">
            <div class="form-group">
              <label for="gender" class="form-label">{{ fields.gender.label }} <sup class="text-danger" v-if="fields.gender.required">*</sup></label>
              <div class="form-group" v-if="fields.gender.choices">
                <template v-for="(choice, key) in fields.gender.choices">
                    <input type="radio" :id="choice.value" v-model="form.gender" name="form.gender" :value="choice.label" :key="'radio_' + key" @click="messageFor(choice.value)">
                    <label class="gender-label-span" :for="choice.value" :key="'gender_label_'+key">{{ choice.label }}</label>
                    <br :key="'gender_br_'+key">
                </template>
                <span class="text-sm text-danger">{{ errors.gender}}</span>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12">
                 <div class="form-group">
                     <label for="citizenship" class="form-label">Citizenship <sup class="text-danger">*</sup></label>
                     <select class="form-control" id="country" v-model="form.citizenship" name="form.citizenship">
                         <option value="">Select</option>
                         <option value="USA">USA</option>
                         <option value="Canada">Canada</option>
                     </select>
                     <span class="text-sm text-danger">{{ errors.citizenship}}</span>
                 </div>
             </div> -->
            <div :class="[fields.veteran_status.colClass]" v-if="fields.veteran_status">
                <div class="form-group">
                    <label for="veteran_status" class="form-label">{{ fields.veteran_status.label }} <sup class="text-danger" v-if="fields.veteran_status.required">*</sup></label>
                    <div class="form-group" v-if="fields.veteran_status.choices">
                        <template v-for="(choice, key) in fields.veteran_status.choices">
                            <input type="radio" :id="choice.value" v-model="form.veteran_status" name="form.veteran_status" :value="choice.label" :key="'radio_' + key">
                            <label class="veteran_status-label-span" :for="choice.value" :key="'veteran_status_label_'+key">{{ choice.label }}</label>
                            <br :key="'veteran_status_br_'+key">
                        </template>
                        <span class="text-sm text-danger">{{ errors.veteran_status}}</span>
                    </div>
                </div>
            </div>
          <div :class="[fields.race.colClass]" v-if="fields.race">
            <div class="form-group radiotext">
              <label for="race" class="form-label-check"><strong>{{ fields.race.label }}</strong> <sup class="text-danger" v-if="fields.race.required">*</sup></label> <br>
              <div v-for="diversity in diversities" :key="diversity.name">
                <label :for="'diversity_id'+diversity.id">
                  <input type="radio" name="form.diversity_id" :value="diversity.id"
                         :id="'diversity_id'+diversity.id"
                         v-model="form.diversity_id" @change="updateDiversityName(diversity.name)">
                  {{diversity.name}}</label> <br>
              </div>
              <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
            </div>
          </div>
            <div :class="[fields.clubslist.colClass]" v-if="fields.clubslist">
                <div class="form-group">
                    <label for="clubslist" class="form-label">{{ fields.clubslist.label }}<sup class="text-danger" v-if="fields.clubslist.required">*</sup></label>
                    <Select2 v-model="form.club" :options="options" name="form.club" :settings="{ placeholder: fields.clubslist.placeholder}" @select="collectClubName" />
                    <p class="mt-2 font-13" v-if="fields.clubslist.description"><i v-html="fields.clubslist.description"></i></p>
                </div>
            </div>
            <div :class="[fields.new_club_name.colClass]" v-if="fields.new_club_name && form.club == '-'">
                <div class="form-group">
                    <label for="new_club_name" class="form-label">{{ fields.new_club_name.label }}<sup class="text-danger" v-if="fields.new_club_name.required">*</sup></label>
                    <input type="text" v-model="form.new_club_name" class="form-control" id="new_club_name" name="new_club_name" placeholder="" value="">
                    <span class="text-sm text-danger">{{ errors.new_club_name }}</span>
                </div>
            </div>
            <div :class="[fields.new_club_email.colClass]" v-if="fields.new_club_email && form.club == '-'">
                <div class="form-group">
                    <label for="new_club_email" class="form-label">{{ fields.new_club_email.label }}<sup class="text-danger" v-if="fields.new_club_email.required">*</sup></label>
                    <input type="text" v-model="form.new_club_email" class="form-control" id="new_club_email" name="new_club_email" placeholder="">
                    <span class="text-sm text-danger">{{ errors.new_club_email }}</span>
                </div>
            </div>
            <div :class="[fields.new_club_phone.colClass]" v-if="fields.new_club_phone && form.club == '-'">
                <div class="form-group">
                    <label for="new_club_phone" class="form-label">{{ fields.new_club_phone.label }}<sup class="text-danger" v-if="fields.new_club_phone.required">*</sup></label>
                    <vue-tel-input v-model="form.new_club_phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                    <span class="text-sm text-danger">{{ errors.new_club_phone }}</span>
                </div>
            </div>
        </div>
      </div>
      <div class="col-md-12" v-show="overAllError != 0">
          <p class="text-danger text-right">Please fill the above missing fields.</p>
      </div>
      <div class="col-md-12">
        <div class="text-right topspace">
          <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>

import DatePick from 'vue-date-pick';
import Select2 from 'v-select2-component';
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';


const validateTitle = (title,fieldName) => {
 
  if (!title.length) {
        return { valid: false, error: "Title is required" };
    }  
  return { valid: true, error: null };
};

const validateName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (!name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  if (!name.match(/^[a-zA-Z' ]+$/))
  {

    return { valid: false, error:  fieldText+ " accept only Alphabets" };
  } 
  return { valid: true, error: null };
};
const validateSuffix = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (!name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  if (!name.match(/^[a-zA-Z-\s]*$/))
  {

    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters " };
  }
  return { valid: true, error: null };
};


const validatePhone = phone => {
  if (!phone.length) {
    return { valid: false, error: 'This field is required.' };
  }

  if (!phone.match(/^[+][(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/gm)) {
    return { valid: false, error: 'Please, enter a valid international phone number.' };
  }

  return { valid: true, error: null };
}

const validateDob =(dob, fieldTxt)  => {
  if (!dob.length) {
    return { valid: false, error: fieldTxt+ " is required" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
const validateGender = gender => {
  if (!gender) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = select => {
  if (!select.length) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = email => {
  if (!email.length) {
    return { valid: false, error: "This field is required" };
  }
  if (!email.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};
const validatePassword = password => {
  if (!password.length) {
    return { valid: false, error: "This field is required" };
  }
  if (password.length < 7) {
    return { valid: false, error: "Password is too short" };
  }
  return { valid: true, error: null };
};
const validateRadio = radio => {
    if (!radio) {
        return {valid: false, error: "This field is required"};
    }
    return {valid: true, error: null};
};

const validateEthnicity = radio => {
  if (!radio||radio==99) {
    return {valid: false, error: "Ethnicity field is required"};
  }
  return {valid: true, error: null};
};

export default {

  name: "personalinformation",
  props: ['membership', 'formData', 'fields', 'clubData', 'diversities'],
  components: {DatePick, Select2 },
  data() {
    return {
      // date: '12-01-2000',

      form: {
        title: '',
        first_name:'',
        middle_name:'',
        last_name:'',
        suffix: '',
        gender:'',
        dob:'',
        relation:0,
        citizenship:'',
        affiliate:'',
        veteran_status: '',
        //shooting
        club: '',
        diversity_txt: '',
        club_name: '',
        new_club_name: '',
        new_club_email: '',
        new_club_phone: ''
        //shooting
      },

      valid: false,
      success: false,
      errors: {},
      message: null,
      options: [],
      validTelinput: false,
      mobileProps: {
          mode: "international",
          defaultCountry: "US",
          placeholder: "Mobile Number",
          // maxLen: 30,
      },      
      overAllError:0
    }
  },
  methods: {
    myChangeEvent(val){
      this.form.affiliate=val;
    },
    mySelectEvent({id, text}){
      this.form.affiliate=id;
      this.form.club_name = text;
    },
    personalData() {
      console.log('validate');
      console.log("affiliate:"+this.form.affiliate);
      // EVENT LOGIC: If component has no validation errors, then EMIT the EVENT
      if(this.validate()) {
        console.log('valid', this.form);
        this.$emit('personal-data', this.form);
        return;
      } else {
        console.log('un valid');
      }
    },
    previousStep() {
      this.$emit('previous-step');
      return;
    },
    // Custom Validation Rules
    validate() {
      this.errors = {}
      this.valid = false;
      this.overAllError = 1;
      var validTitle = {valid:true};
      // if(this.subdomain == "shooting")
      // {
      //   validTitle = validateTitle(this.form.title, "Title" );
      //   this.errors.title = validTitle.error;
      // }

      const validFirstName = validateName(this.form.first_name, "First name");
      this.errors.first_name = validFirstName.error;      

      const validLastName = validateLastName(this.form.last_name, "Last name");
      this.errors.last_name = validLastName.error;      

      const validDob = validateDob(this.form.dob, "Birth Date");
      this.errors.dob = validDob.error;

      const validSuffix = validateSuffix(this.form.suffix, "Suffix");
      this.errors.suffix = validSuffix.error;

      const validGender = validateGender(this.form.gender, "Gender");
      this.errors.gender = validGender.error;      

      // const validSelect = validateSelect(this.form.citizenship);
      // this.errors.citizenship = validSelect.error;

      if (validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid && validSuffix.valid && validTitle.valid) {
        let dob = this.getAge(this.form.dob);        

        // if(this.membership.id == 1 && dob < 25) {
        //   console.log('dob1='+dob);
        //   this.valid = false;
        //   this.errors.dob = 'Individual Active user age should be age 25 or older. Go back and change membership type to U25 Active membership.';
        // } else if(this.membership.id == 2 && dob >= 25) {
        //   console.log('dob1='+dob);
        //   this.valid = false;
        //   this.errors.dob = 'Individual Under 25 Active user age should be less than 25';
        // } else if(this.membership.id == 5 && dob < 17) {
        //   console.log('dob3='+dob);
        //   this.valid = false;
        //   this.errors.dob = 'Family Head of Household must be age 18 or above. Please change Head of Household to an adult member of the family or go back and choose another membership type.';
        // } else {
        //   console.log('dob2='+dob);
        //   this.errors.dob = '';
        //   this.valid = true;
        // }

        if(!this.validateDate(this.form.dob)){
          this.valid = false;
          this.overAllError= 1;
          this.errors.dob = 'Invalid Date';
        } else {
          this.errors.dob = '';
          if(this.membership.age_criteria){
              if(this.membership.age_criteria.compare=="above" && dob < this.membership.age_criteria.value) {
                  this.valid = false;
                  this.overAllError= 1;
                  this.errors.dob = this.membership.age_criteria.error_msg;
              } else if(this.membership.age_criteria.compare=="below" && dob > this.membership.age_criteria.value) {
                  this.valid = false;
                  this.errors.dob = this.membership.age_criteria.error_msg;
                  this.overAllError= 1;
              } else {
                  this.errors.dob = '';
                  this.valid = true;
                  this.overAllError= 0;
              }
          } else {
              this.valid = true;
              this.overAllError= 0;
          }
        }
      }

      if(document.querySelector(".personal-type [name='form.veteran_status']") != null){
          const validMilitary = validateRadio(this.form.veteran_status, "Military Status");
          this.errors.veteran_status = validMilitary.error;
          if(!validMilitary.valid) this.valid = false;
      }
      if(document.querySelector(".personal-type [name='form.diversity_id']") != null){
        const validEthnicity = validateEthnicity(this.form.diversity_id, "Ethnicity Status");
        this.errors.diversity_id = validEthnicity.error;
        if(!validEthnicity.valid) this.valid = false;
      }

      return this.valid;
    },
    getAge(birth_date) {
      let birth_date_arr = birth_date.split('/');
      console.log(birth_date_arr);
      let today_date = new Date();
      let today_year = today_date.getFullYear();
      let today_month = today_date.getMonth();
      let today_day = today_date.getDate();
      let age = today_year - birth_date_arr[2];

      if ( today_month < (birth_date_arr[0] - 1))
      {
        age--;
      }
      if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1]))
      {
        age--;
      }
      return age;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    getClubs(){
      axios.get(this.basePath + "api/get_teams")
          .then((response) => {
            var clubs = response.data.data;
            this.options = clubs;
          })
          .catch(error=>{
            console.log("error geting clubs list")
          })
    },
    collectClubName(e){
      this.form.club = e.id;
      this.form.club_name = e.text;
      this.$forceUpdate();
    },
    validateTelinput(obj){            
        this.validTelinput = obj.valid;
        if(obj.valid){
            this.errors.new_club_phone = '';
        }
    },
    messageFor(val){
      this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
        },
    updateDiversityName(text){
      this.form.diversity_txt = text;
    },
  },
  mounted(){

    console.log('Personal Data');

    this.form = this.formData[0];
    this.getClubs();

    const retry = setInterval(() => {
      if(this.clubData.club){
        var keys = Object.keys(this.clubData);
        for(let key of keys){
          this.form[key] = this.clubData[key];
        }
        clearInterval(retry);
      }
    }, 200);

     const retry1 = setInterval(() => {
      if(this.clubData.multiple_club){
         this.form.affiliate = Object.keys(this.clubData.multiple_club);
        clearInterval(retry1);
      }
    }, 200);

    // const retry2 = setInterval(() => {
    //   const retry3 = setInterval(() => {
    //     if(this.membership.id){
    //       let membershipTypes = this.getSiteProps('membershipTypes');
    //       if(membershipTypes){
    //         for(let mtype of membershipTypes){
    //           for(let item of mtype.memberships){
    //             if(item.id == this.membership.id){
    //               this.membership.age_criteria = item.age;
    //               this.membership.member_type_id = mtype.id;
    //               break;
    //             }
    //           }
    //           if(this.membership.age_criteria) break;
    //         }
    //         clearInterval(retry3);
    //       }
    //       clearInterval(retry2);
    //     }
    //   }, 200);
    // }, 200);
  },
  updated(){
    // this.form = this.formData[0];
    if(this.membership.id){
      let membershipTypes = this.getSiteProps('membershipTypes');
      if(membershipTypes){
        for(let mtype of membershipTypes){
          for(let item of mtype.memberships){
            if(item.id == this.membership.id){
              this.membership.age_criteria = item.age;
              this.membership.member_type_id = mtype.id;
              break;
            }
          }
          if(this.membership.age_criteria) break;
        }
      }
    }
  }
}


</script>
