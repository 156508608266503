<template>
<div class="card family-members">
<div class="card-header register-title">
    <h3 class="mb-3">
        {{ fields.tab_title || 'Family Members'}}
        <b-button v-if="fields.description" v-b-tooltip.hover :title="fields.description"> 
            <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
        </b-button>
    </h3>
</div>
<div class="card-body addnewbutn">
  <div class="alert alert-warning" v-if="childDetachDetected">{{ childDetachMessage }}</div>
    <b-button v-b-modal.modal-center3 @click.prevent="familyMembersmodal" v-if="(familyMembers.length+1) <= (fields.total_members || 5)"> + Add New Member</b-button>
    <span class="text-sm text-danger">{{ errors.primary }}</span>
    <b-modal id="modal-center3" size="lg" ok-only centered :title="fields.popup.title || 'Family Member Details'"
             @hidden="resetModal"
             ok-title="Save"  @ok="saveMember">
        <div class="popupbody">
            <div class="row">
                <div class="col-md-6" v-if="fields.popup.relation">
                    <label class="form-label">{{ fields.popup.relation.label }} <sup class="text-danger">*</sup></label><br>
                    <div class="d-flex">
                        <template v-for="(choice,k) in fields.popup.relation.choices">
                            <div :class="[choice.containerClass]" :key="'relation'+k">
                                <input type="radio" :id="choice.id" @change="removeHohEmail(choice.value)" name="form.relation" :value="choice.value" v-model="form.relation">
                                <span :for="choice.id" class="gender-label-span">{{ choice.label }}</span>
                            </div>
                        </template>
                    </div>
                    <span class="text-sm text-danger">{{ errors.relation }}</span>
                    <p class="text-sm text-success" v-if="form.relation == 2">{{ fields.popup.relation.choices[1].description }}</p>
                </div>
                <div class="col-md-6" v-if="fields.popup.email">
                    <div class="form-group">
                        <label for="email" class="form-label">{{ fields.popup.email.label }} <sup class="text-danger">*</sup></label>
                        <input type="text" class="form-control" maxlength="45" name="form.email" v-model="form.email" >
                        <span class="text-sm text-danger">{{ errors.email }}</span>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-md-4" v-if="fields.popup.firstName">
                <div class="form-group">
                    <label for="firstName" class="form-label">{{ fields.popup.firstName.label }} <sup class="text-danger" v-if="fields.popup.firstName.required">*</sup></label>
                    <input type="text" class="form-control" maxlength="45" name="form.first_name" v-model="form.first_name" >
                    <span class="text-sm text-danger">{{ errors.first_name }}</span>
                </div>
            </div>
            <div class="col-md-2" v-if="fields.popup.middleInitial">
                <div class="form-group">
                    <label for="middleInitial" class="form-label">Middle Initial</label>
                    <input type="text" maxlength="3" name="form.middle_name" v-model="form.middle_name" class="form-control">
                    <span class="text-sm text-danger">{{ errors.middle_name }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.LastName">
                <div class="form-group">
                    <label for="LastName" class="form-label">{{ fields.popup.LastName.label }} <sup class="text-danger" v-if="fields.popup.LastName.required">*</sup></label>
                    <input type="text" maxlength="45" name="form.last_name" v-model="form.last_name" class="form-control">
                    <span class="text-sm text-danger">{{ errors.last_name }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.birthDay">
                <label for="birthDay" class="form-label">{{ fields.popup.birthDay.label }} <sup class="text-danger" v-if="fields.popup.birthDay.required">*</sup></label>
                <div class="input-group birthdate">
                    <div class="birthdaypicker">
                        <!-- <b-form-datepicker id="family-datepicker" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="form.dob" class="mb-2"></b-form-datepicker> -->
                        <!-- <i class="fas fa-calendar-alt"></i> -->
                        <date-pick
                            v-model="form.dob"
                            :pickTime="false"
                            name="form.dob"
                            :selectableYearRange="{from: 1900, to: 2021}"
                            :format="'MM/DD/YYYY'"
                            v-mask="'##/##/####'"
                            :isDateDisabled="isFutureDate"
                            :parseDate="parseDatePick"
                        ></date-pick>
                    </div>
                    <span class="text-sm text-danger">{{ errors.dob }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.gender">
                <label for="gender" class="form-label">{{ fields.popup.gender.label }} <sup class="text-danger">*</sup></label>
                <div class="form-group">
                    <div class="row">
                        <div class="mb-1" :class="k < 3 ? 'col-md-3' : 'col-md-5'" v-for="(choice, k) in fields.popup.gender.choices" :key="'gender_'+k">
                            <input type="radio" :id="choice.id" v-model="form.gender" name="form.gender" :value="choice.label" @click="messageFor(choice.id)">
                            <span class="gender-label-span" :for="choice.id">{{ choice.label }}</span>
                        </div>
                    </div>
                    <span class="text-sm text-danger">{{ errors.gender }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.homephone">
                <div class="form-group ">
                    <div class="phone-nu-wrapper">
                        <label for="country_code_h" class="form-label">{{ fields.popup.homephone.label }}<sup class="text-danger" v-if="fields.popup.homephone.required">*</sup></label>
                        <vue-tel-input :inputOptions="options" @validate="validateTelinput" v-model="form.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.phone_1 }}</span>
                       <!--<input type="text" class="form-control"  id="country_code_h" name="country_code_h" placeholder="+1">
                        <input type="tel" class="form-control" id="phone_1" v-model="form.phone_1"  name="form.phone_1">-->
                    </div>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.mobile">
                <div class="form-group">
                    <div class="phone-nu-wrapper">
                        <label for="phone_2" class="form-label">{{ fields.popup.mobile.label }}</label>
                        <vue-tel-input :inputOptions="options" v-model="form.phone_2" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.phone_2 }}</span>
                        <!--<input type="text" class="form-control" id="country_code_m" name="country_code" placeholder="+1">
                        <input type="tel" class="form-control" id="phone_2" v-model="form.phone_2"  name="form.phone_2">-->
                    </div>
                </div>
            </div>
            </div>
            <div class="row" v-if="fields.popup.sport_discipline">
            <div class="col-md-12" v-if="fields.popup.sport_discipline.label">
                <label class="form-check-label form-label">{{ fields.popup.sport_discipline.label }}</label>
            </div>
            <div :class="first_choice_class" v-if="fields.popup.sport_discipline.first_choice">
                <div class="form-group">
                    <label for="first-choice" class="form-label">{{ fields.popup.sport_discipline.first_choice.label }} <sup class="text-danger" v-if="fields.popup.sport_discipline.first_choice.required">*</sup></label>
                    <Select2 v-if="fields.popup.sport_discipline.first_choice.multiple" v-model="form.discipline_1" 
                    name="form.discipline_1" id="first-choice" :options="disciplines_list" 
                    :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" @change="collectDisciplines" />
                    <select class="form-control" id="first-choice" name="form.discipline_1" @change="collectDisciplines" v-model="form.discipline_1" v-else>
                        <option value="">Select</option>
                        <option v-for="(discipline, index) in disciplines" :value="index"
                                :key="'types'+index">{{ discipline }}
                        </option>
                    </select>
                    <span class="text-sm text-danger">{{ errors.discipline_1 }}</span>
                </div>
            </div>
            <div class="col-md-6" v-if="fields.popup.sport_discipline.second_choice">
                <div class="form-group">
                    <label for="secondchoice" class="form-label">{{ fields.popup.sport_discipline.second_choice.label }}</label>
                    <select class="form-control" id="secondchoice" name="form.discipline_2" v-model="form.discipline_2" @change="collectSecondChoice">
                        <option value="">Select</option>
                        <option v-for="(discipline, index) in disciplines" :value="index"
                                :key="'types2-'+index">{{ discipline }}
                        </option>
                    </select>
                    <span class="text-sm text-danger">{{ errors.discipline_2 }}</span>
                </div>
            </div>
            </div>
            <div class="row">
              <div :class="[fields.popup.clubandteam.colClass]" v-if="fields.popup.clubandteam">
                <div class="form-group">
                  <label for="clubandteam" class="form-label">{{ fields.popup.clubandteam.label }}<sup class="text-danger"
                                                                                                 v-if="fields.popup.clubandteam.required">*</sup></label>
                  <Select2 v-model="form.multiple_affiliates_arr" :options="clubOptions" :settings="select2MultipleOpt"/>
                  <p class="mt-2 font-13" v-if="fields.popup.clubandteam.description"><i
                      v-html="fields.popup.clubandteam.description"></i></p>
                </div>
              </div>
              <div :class="[fields.popup.address1.colClass]" v-if="fields.popup.address1 && subdomain!='waterski'">
                    <div class="form-group">
                    <label for="firstname" class="form-label">{{ fields.popup.address1.label }} <sup class="text-danger" v-if="fields.popup.address1.required">*</sup><span v-if="fields.popup.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ fields.popup.address1.charLimitText }}</span></label>
                    <input type="text" class="form-control" :maxlength=fields.popup.address1.charLimitValue id="address1" v-model="form.address1" name="form.address1">
                    <span class="text-sm text-danger">{{ errors.address1}}</span>
                    </div>
                </div>
                <div :class="[fields.popup.address2.colClass]" v-if="fields.popup.address2 && subdomain!='waterski'">
                    <div class="form-group">
                    <label for="firstname" class="form-label">{{ fields.popup.address2.label }} <span v-if="fields.popup.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ fields.popup.address2.charLimitText }}</span></label>
                    <input type="text" class="form-control" :maxlength=fields.popup.address2.charLimitValue id="address2" v-model="form.address2" name="form.address2">
                    <!--<span class="text-sm text-danger">{{ errors.address2}}</span>-->
                    </div>
                </div>
                    <div :class="[fields.popup.country.colClass]" v-if="fields.popup.country && subdomain!='waterski'">
                        <div class="form-group">
                        <label for="Country" class="form-label">{{ fields.popup.country.label }} <sup class="text-danger" v-if="fields.popup.country.required">*</sup></label>
                        <select class="form-control" id="country" @change="removeStateCode" v-model="form.country" name="form.country">
                            <option value="">Select</option>
                            <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.country}}</span>
                        </div>
                    </div>
                    <div :class="[fields.popup.city.colClass]" v-if="fields.popup.city && subdomain!='waterski'">
                        <div class="form-group">
                        <label for="city" class="form-label">{{ fields.popup.city.label }} <sup class="text-danger" v-if="fields.popup.city.required">*</sup></label>
                        <input type="text" class="form-control" maxlength="45" id="city"  v-model="form.city" name="form.city">
                        <span class="text-sm text-danger">{{ errors.city}}</span>
                        </div>
                    </div>
                    <div :class="[fields.popup.state_code.colClass]" v-if="form.country == 'USA' && fields.popup.state_code">
                        <div class="form-group" v-if="subdomain!='waterski'">
                        <label for="state_code" class="form-label">{{ fields.popup.state_code.label }} <sup class="text-danger" v-if="fields.popup.state_code.required">*</sup></label>
                        <select class="form-control" id="state_code" v-model="form.state_code" name="form.state_code" >
                            <option value="">Select</option>
                            <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.state_code}}</span>
                        </div>
                    </div>
                    <div :class="[fields.popup.state_code.colClass]" v-else-if="form.country == 'Canada' && fields.popup.state_code">
                      <div class="form-group" v-if="subdomain!='waterski'">
                        <label for="state_code" class="form-label">{{ fields.popup.state_code.label }} <sup class="text-danger" v-if="fields.popup.state_code.required">*</sup></label>
                        <select class="form-control" id="state_code" v-model="form.state" name="form.state" >
                          <option value="">Select</option>
                          <option  v-for="listOfState in listOfCAStates" :key="listOfState.state" :value="listOfState.state">{{ listOfState.state }}</option>
                        </select>
                        <span class="text-sm text-danger">{{ errors.state}}</span>
                      </div>
                    </div>
                    <div :class="[fields.popup.other_state.colClass]" v-else>
                        <div class="form-group" v-if="fields.popup.other_state && subdomain!='waterski'">
                        <label for="state_code" class="form-label">{{ fields.popup.other_state.label }} </label>
                        <input type="text" class="form-control" maxlength="45" v-model="form.state" name="form.state">
                        <span class="text-sm text-danger">{{ errors.state}}</span>
                        </div>
                    </div>

                    <div :class="[fields.popup.zip.colClass]" v-if="fields.popup.zip && subdomain!='waterski'">
                        <div class="form-group">
                        <label for="zip" class="form-label">{{ fields.popup.zip.label }} <sup class="text-danger" v-if="fields.popup.zip.required">*</sup></label>
                        <input type="text" class="form-control" :maxlength="getSiteProps('general.zip_code_length')" id="zip" v-model="form.zip" name="form.zip">
                        <span class="text-sm text-danger">{{ errors.zip}}</span>
                        </div>
                    </div>
              <div :class="[fields.popup.home_federation.colClass]" v-if="fields.popup.home_federation">
                <div class="form-group">
                  <label for="home_federation" class="form-label">{{ fields.popup.home_federation.label }} <sup v-if="fields.popup.home_federation.required">*</sup></label>
                  <select class="form-control" id="home_federation" name="form.home_federation"
                          v-model="form.home_federation">
                    <option value="">Select</option>
                    <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                  </select>
                  <span class="text-sm text-danger">{{ errors.home_federation }}</span>
                </div>
              </div>
              <div :class="[fields.popup.skier_license.colClass]" v-if="fields.popup.skier_license">
                <div v-if="form.home_federation !='USA'">
                  <div class="form-group">
                    <label for="skier_license" class="form-label">{{ fields.popup.skier_license.label }}<sup v-if="fields.popup.skier_license.required">*</sup></label>
                    <input type="text" id="skier_license" class="form-control" maxlength="9" @keypress="isNumber($event)"
                           placeholder="" name="form.skier_license"
                           v-model="form.skier_license">
                    <span class="text-sm text-danger">{{ errors.skier_license }}</span><br/>
                    <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                    <div class="text-sm text-success" v-if="fields.popup.skier_license.description" v-html="fields.popup.skier_license.description"></div>
                  </div>
                </div>
              </div>
              <!-- Demographic start-->
            <div :class="[fields.popup.race.colClass]" v-if="fields.popup.race">
              <div class="form-group radiotext">
                <label for="race" class="form-label-check"><strong>{{ fields.popup.race.label }}</strong> <sup
                    class="text-danger" v-if="fields.popup.race.required">*</sup></label> <br>
                <div v-for="diversity in diversities" :key="diversity.name">
                  <label :for="'diversity_id'+diversity.id">
                    <input type="radio" name="form.diversity_id" :value="diversity.id"
                           :id="'diversity_id'+diversity.id"
                           v-model="form.diversity_id" @change="saveDiversityTxt(diversity.name)">
                    {{ diversity.name }}</label> <br>
                </div>
                <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
              </div>
            </div>
            <div :class="[fields.popup.ethnicity.colClass]" v-if="fields.popup.ethnicity">
              <div class="form-group radiotext">
                <label for="ethnicitystatus" class="form-label-check"><strong>{{ fields.popup.ethnicity.label }}</strong><sup
                    class="text-danger" v-if="fields.popup.ethnicity.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.ethnicity.choices">
                  <template v-for="(choice, k) in fields.popup.ethnicity.choices">
                    <input type="radio" :id="choice.id" name="ethnicity" :value="choice.label" v-model="form.ethnicity"
                           :key="'ethnicity_'+k">
                    <label :for="choice.id" :key="'ethnicity_label_'+k">{{ choice.label }}</label>
                    <br :key="'ethnicity_br_'+k">
                  </template>
                  <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.popup.disability_status.colClass]" v-if="fields.popup.disability_status">
              <div class="form-group radiotext">
                <label for="disabilitystatus" class="form-label-check"><strong>{{
                    fields.popup.disability_status.label
                  }}</strong> <sup class="text-danger" v-if="fields.popup.disability_status.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.disability_status.choices">
                  <template v-for="(choice, k) in fields.popup.disability_status.choices">
                    <input type="radio" :id="choice.id" name="disability" :value="choice.label"
                           v-model="form.disability" :key="'ds_'+k">
                    <label :for="choice.id" :key="'ds_label_'+k">{{ choice.label }}</label>
                    <br :key="'ds_br_'+k">
                  </template>
                  <span class="text-sm text-danger">{{ errors.disability }}</span>
                </div>
              </div>
            </div>
            <div :class="[fields.popup.veteran_status.colClass]" v-if="fields.popup.veteran_status  && form.relation == 1">
              <div class="form-group radiotext">
                <label for="veteranstatus" class="form-label-check"><strong>{{
                    fields.popup.veteran_status.label
                  }}</strong><sup class="text-danger" v-if="fields.popup.veteran_status.required">*</sup></label>
                <div class="form-group" v-if="fields.popup.veteran_status.choices">
                  <template v-for="(choice, k) in fields.popup.veteran_status.choices">
                    <input type="radio" :id="choice.id" name="veteran_status" :value="choice.label"
                           v-model="form.veteran_status" :key="'vs_'+k">
                    <label :for="choice.id" :key="'vs_label_'+k">{{ choice.label }}</label>
                    <br :key="'vs_br_'+k">
                  </template>
                  <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                </div>
              </div>
            </div>
        </div>

        </div>
        <div class="col-md-12" v-show="overAllError != 0">
          <p class="text-danger text-right">Please fill the above missing fields.</p>
        </div>
    </b-modal>
<!--    <div class="row">

    </div>-->
    <!-- <pre>
        {{ familyMembers }}
    </pre> -->
    <template v-for="(member, index) in familyMembers">
    <div class="row memberdetails" :key="index">
        <div class="col-md-10">
          <h3 class="mb-3">{{ index + 1 }}. {{ member.first_name }} {{ member.middle_name || '' }} {{ member.last_name }} ({{ relations[member.relation] }})
            <span class="ml-2 text-danger" v-if="member.relation == 2 && isChildDetach(member.dob, next_renewal_period.start_date) && isShownErrorMessage"> -- Will be detached; please use remove child to proceed.  <span v-b-tooltip.hover title="Age will be over 17 when renewal period starts"><i class="fas fa-info-circle"></i></span> </span>
            <span class="ml-2 text-danger" v-else-if="member.relation == 2 && isChildDetach(member.dob, next_renewal_period.start_date)"> -- Will be detached <span v-b-tooltip.hover title="Age will be over 17 when renewal period starts"><i class="fas fa-info-circle"></i></span> </span>
            <span v-else-if="isShownErrorMessage" class="text-sm text-danger">{{ member.error_message }}</span> </h3>
        </div>
        <div class="col-md-2">
            <div class="d-flex float-right">
                <button type="button" class="btn-edit mr-1" @click="editFamily(index)"><img v-bind:src="'/dist/img/memberregistration/edit.png'" alt="edit_icon"> Edit</button>
                <button type="button" class="btn-remove ml-1 " @click="removeFamily(index, member.current_member_id)"><img v-bind:src="'/dist/img/memberregistration/remove.png'" alt="edit_icon">Remove</button>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="form-group">
            <label class="form-label">First Name</label>
            <span class="title">{{member.first_name}}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
            <label class="form-label">Last Name</label>
            <span class="title">{{member.last_name}}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="form-label">Relationship</label>
                <span class="title">{{relations[member.relation]}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.middleInitial">
            <div class="form-group">
                <label class="form-label"><strong>{{ fields.popup.middleInitial.label || 'Middle Initial'}}</strong></label>
                <span class="title">{{member.middle_name || 'N/A'}}</span>
            </div>
        </div> -->
        <div class="col-lg-6" v-if="fields.popup.birthDay">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.birthDay.label || 'Birthday'}}</strong></label>
            <span class="title">{{member.dob}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.gender">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.gender.label || 'Gender'}}</strong></label>
            <span class="title">{{member.gender}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.email">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.email.label || 'E-Mail'}}</strong></label>
            <span class="title">{{member.email}}</span>
            </div>
        </div>
      <div class="col-lg-6" v-if="fields.popup.clubandteam">
        <div class="form-group">
          <label class="form-label"><strong>{{ fields.popup.clubandteam.label || 'Affiliated Clubs/Teams'}}</strong></label>
          <ul v-if="member.multiple_affiliates_arr.length > 0" class="pl-3">
            <li v-for="(item, k) in clubOptions.filter(item => member.multiple_affiliates_arr.map(str => parseInt(str, 10)).includes(item.id)).map(item => item.text)" :key="'v-club'+k">
              {{ item }}
            </li>
          </ul>
          <span v-else>{{ 'N/A' }}</span>
        </div>
      </div>
        <div class="col-lg-6" v-if="fields.popup.homephone">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.homephone.label || 'Home Phone'}}</strong></label>
            <span class="title">{{member.phone_1}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.mobile">
            <div class="form-group">
            <label class="form-label"><strong>{{ fields.popup.mobile.label || 'Mobile Phone'}}</strong></label>
            <span class="title">{{member.phone_2 || 'N/A'}}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.sport_discipline && fields.popup.sport_discipline.first_choice && member.displines_names">
            <div class="form-group">
                <label class="form-label"><strong>{{ fields.popup.sport_discipline.first_choice.label || 'Disciplines'}}</strong></label>
                <template v-if="member.displines_names.length > 1">
                    <ul class="pl-3" v-if="member.displines_names.length">
                        <li v-for="(item, k) in member.displines_names" :key="'dn-'+k"><span class="title">{{ item }}</span></li>
                    </ul>
                </template>
                <span class="title" v-else>{{ member.displines_names | joinArray }}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.sport_discipline && fields.popup.sport_discipline.second_choice && member.secondChoiceNames">
            <div class="form-group">
                <label class="form-label"><strong>{{ fields.popup.sport_discipline.second_choice.label || '2nd Choice'}}</strong></label>
                <template v-if="member.secondChoiceNames.length > 1">
                    <ul class="pl-3" v-if="member.secondChoiceNames.length">
                        <li v-for="(item, k) in member.secondChoiceNames" :key="'dn-'+k"><span class="title">{{ item }}</span></li>
                    </ul>
                </template>
                <span class="title" v-else>{{ member.secondChoiceNames | joinArray }}</span>
            </div>
        </div>
        <div class="col-lg-6" v-if="fields.popup.race && member.diversity_id">
            <div class="form-group">
                <label class="form-label">
                    <strong>{{ fields.popup.race.label || 'Race'}}</strong>
                </label>
                <span class="title">{{ member.diversity_txt || 'N/A' }}</span>
            </div>
        </div>
    </div>
    </template>

    <div class="row">
        <div class="col-md-12 text-right topspace ">
          <span class="d-flex justify-content-end text-sm text-danger">{{ errors.overAllError }}</span>
            <div class="d-flex float-right ">
                <button type="button" @click="previousStep" class="btn btn-outline-primary savebtn"><i class="fas fa-chevron-left pr-2"></i>Previous</button>
                <button type="button" @click="ClubContactCheck" class="btn btn-outline-primary savebtn next-step-btn mr-0">Next<i class="fas fa-chevron-right pl-2"></i></button>
            </div>
        </div>
    </div>
</div>
</div>
</template>


<script>

const validateFirstName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateName = (name, fieldTxt) => {
    // console.log('name lenghth='+name.length);
    if (name == null || !name.length) {
        return { valid: false, error: fieldTxt+ "is required" };
    }
    return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, fieldTxt ) => {
    if (!phone.length) {
    return { valid: false, error: fieldTxt+ 'is required.' };
}
if(validTelinput == false && phone.length){
  return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
}
    return { valid: true, error: null };
};

const validateSelect = (select, fieldTxt) => {
    if ( select == '' || select == null) {
        return { valid: false, error: fieldTxt+ " is required" };
    }
    return { valid: true, error: null };
};
const validateRadio = (radio, fieldTxt) => {
    if (!radio.length) {
        return { valid: false, error: fieldTxt+ " is required" };
    }
    console.log('radio value='+radio);
    return { valid: true, error: null };
};
const validateRadioBtn = (radio, fieldTxt) => {
  if (!radio) {
    return { valid: false, error: fieldTxt + " is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = (email, fieldTxt) => {
    if (email == '' || email == null) {
        return { valid: false, error: fieldTxt+ " is required" };
    }
    if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
        return { valid: false, error: "Please enter a valid email." };
    }
    return { valid: true, error: null };
};
const validateFedration = (name, fieldTxt) => {
  if (!name) {
    return {valid: false, error: fieldTxt+" is required"};
  } else if (isNaN(name.trim())) {
    return {valid: false, error: fieldTxt+" must be a numeric value"};
  }
//    else if (name.length != 9) {
//     return {valid: false, error: "Foreign federation ID must be 9 characters"};
//   }
  return {valid: true, error: null};
};

const validateDob = (dob, fieldDob = '') => {
    let fieldDobText = (fieldDob != '' ? fieldDob : "This field");
    if (!dob.length) {
        return { valid: false, error: fieldDobText+" is required" };
    }
    /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
    return { valid: false, error: "Please, enter a valid data of birth." };
    }*/
    return { valid: true, error: null };
};

const validateZip = (zipCode, country) => {
  if(!zipCode.length)
  {
    return { valid: false, error: "Zip code is required" };
  }
  else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
    return { valid: false, error: "Zip Code Should Be 5 Number Values for USA." };
  }
  else if(zipCode.length < 5 ){       
         return { valid: false, error: "Zip code should be 5 character" };
  }
  return { valid: true, error: null };
}

import { VueTelInput } from 'vue-tel-input';
import axios from 'axios';
// import {TheMask} from 'vue-the-mask'
import DatePick from 'vue-date-pick';

export default {
    name: "familymembers",
    components: {VueTelInput, DatePick},
    props: ['disciplines','members','currentRegContacts', 'fields', 'groups', 'diversities','next_renewal_period'],

    data() {
        return {
            validTelinput:false,
            select2MultipleOpt:{ multiple: true, placeholder: 'Click to select one or multiple' },
            options: { placeholder: "Phone Number" },
            startDate: '2015-01-01',
            showModal: false,

            phone: "0123456789",
            homeProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Home Number",
                maxLen: 10,
            },
            mobileProps: {
                mode: "international",
                defaultCountry: "US",
                placeholder: "Mobile Number",
                maxLen: 10,
            },
            form: {
                "first_name": '',
                "middle_name": '',
                "last_name": '',
                "email": '',
                "gender": '',
                "dob": '',
                "relation": '0',
                "citizenship": '',
                "multiple_affiliates_arr": '',
                'discipline_1': '',
                'discipline_2': '',
                'index': 1,
                phone_1: '',
                phone_2: '',
                displines_names: [],
                secondChoiceNames: [],
                home_federation: 'USA',
                skier_license: '',
                diversity_id: '',
                diversity_txt: '',
                ethnicity: '',
                disability: '',
                veteran_status: '',
                current_member_id: "",
                address1: '',
                address2: '',
                zip: '',
                state_code: '',
                state: '',
                city: '',
                country: ''
            },
            familyMembers: [],
            removedFamilyMem: [],
            valid: false,
            success: false,
            errors: {},
            message: null,
            spouseAdded: {},
            relations: {
                0: 'HOH',
                1: 'Spouse',
                2: 'Child',
                3: "Dependent"
            },
            disciplines_list: [],
            action: '',
            clubOptions:[],
           overAllError:0,
          childDetachDetected: false,
            isValidFamilyInfo:0,
            isShownErrorMessage:false,
        }
    },

    methods: {
        familyMembersmodal() {
            //this.$refs['my-modal'].show();
            this.form.index = this.familyMembers.length;
            this.form.current_member_id = "";

            this.form.email = this.currentRegContacts.email;
            this.form.phone_1 = this.currentRegContacts.phone_1;
            this.form.phone_2 = this.currentRegContacts.phone_2;
            this.form.discipline_1 = this.currentRegContacts.discipline_1;
            this.form.discipline_2 = this.currentRegContacts.discipline_2;
            this.form.address1 = this.currentRegContacts.address1;
            this.form.address2 = this.currentRegContacts.address2;
            this.form.state_code = this.currentRegContacts.state_code;
            this.form.state = this.currentRegContacts.state;
            this.form.city = this.currentRegContacts.city;
            this.form.zip = this.currentRegContacts.zip;
            this.form.country = this.currentRegContacts.country;
            this.errors = {};

            this.collectDisciplines();
            this.collectSecondChoice();
            this.action = 1;
        },
        getClubs(){
          axios.get(this.basePath + "api/get_teams")
              .then((response) => {
                var clubs = response.data.data;
                this.clubOptions = clubs;
              })
              .catch(error=>{
                console.log("error geting clubs list")
              })
        },
        saveMember(bvModalEvt) {
            bvModalEvt.preventDefault();
            if (!this.validate()) {
                return;
            }

            if(
                (this.action == 1 && this.form.relation == 1) || 
                (this.action == 2 && this.form.relation == 1 && this.form.relation != this.familyMembers[this.form.index].relation)
            ) {
                this.spouseAdded.valid = true;
            }

            if(this.action == 2 && this.form.relation != 1 && this.familyMembers[this.form.index].relation == 1){
                this.spouseAdded.valid = false;
            }

            // this.$emit('family-data', this.form);
            this.checkFamilyMemberDemographic(this.form);
            this.familyMembers[this.form.index] = this.form;

            this.form.index = this.familyMembers.length;
            this.errors = {};
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center3')
            })
        },
        resetModal() {
            this.form = {
                first_name: '',
                middle_name: '',
                last_name: '',
                email: '',
                gender: '',
                dob: '',
                relation: '',
                citizenship: '',
                multiple_affiliates_arr: '',
                discipline_1: '',
                discipline_2: '',
                home_federation: 'USA',
                skier_license: '',
                diversity_id: '',
                ethnicity: '',
                disability: '',
                veteran_status: '',
                address1: '',
                address2: '',
                zip: '',
                state_code: '',
                state: '',
                city: '',
                country: ''
            }
        },
        previousStep() {
            this.$emit('previous-step');
            return;
        },
        removeFamily(i, member_id) {
            if(this.familyMembers[i].relation == 1) {
                this.spouseAdded.valid = false;
            }
            // this.$emit('family-remove-data', i);
          this.checkFamilyMemberDemographic(i,false);
            this.familyMembers = this.familyMembers.filter((item, index) => index != i);
            this.removedFamilyMem.push(member_id);
        },
        editFamily(i) {
            console.log('update famildy' + i);
            this.errors = {};
            //this.form = this.familyMembers[i];
            this.form = {
                current_member_id: this.familyMembers[i].current_member_id,
                first_name: this.familyMembers[i].first_name,
                middle_name: this.familyMembers[i].middle_name,
                last_name: this.familyMembers[i].last_name,
                email: this.familyMembers[i].email,
                gender: this.familyMembers[i].gender,
                dob: this.familyMembers[i].dob,
                relation: this.familyMembers[i].relation,
                citizenship: '',
                multiple_affiliates_arr: this.familyMembers[i].multiple_affiliates_arr,
                discipline_1: this.familyMembers[i].discipline_1,
                discipline_2: this.familyMembers[i].discipline_2,
                phone_1: this.familyMembers[i].phone_1,
                phone_2: this.familyMembers[i].phone_2,
                home_federation: this.familyMembers[i].home_federation,
                skier_license: this.familyMembers[i].skier_license,
                diversity_id: (this.familyMembers[i].diversity_id == 99)?null:this.familyMembers[i].diversity_id,
                diversity_txt: this.familyMembers[i].diversity_txt,
                ethnicity: this.familyMembers[i].ethnicity,
                disability: this.familyMembers[i].disability,
                veteran_status: this.familyMembers[i].veteran_status,
                address1: this.familyMembers[i].address1,
                address2: this.familyMembers[i].address2,
                zip: this.familyMembers[i].zip,
                state_code: this.familyMembers[i].state_code,
                state: this.familyMembers[i].state,
                city: this.familyMembers[i].city,
                country: this.familyMembers[i].country
            }
            console.log(this.form);
            this.form.index = i;
            this.$bvModal.show('modal-center3')
            this.action = 2;
            this.collectDisciplines();
            this.collectSecondChoice();
        },
        ClubContactCheck: function () {
            this.errors = {}
            console.log('this contact check');
            // console.log(this.valid);
            console.log("lengthvc" + this.familyMembers.length);
            if (this.familyMembers.length > 0 && this.isValidFamilyInfo == 0) {
                console.log("length" + this.members.length);
                this.errors.primary = '';

                this.$emit('family-data', { familyMembers: this.familyMembers, removedFamilyMem: this.removedFamilyMem });

                this.$emit('next-step');
                return;
            } else if(this.familyMembers.length > 0 && this.isValidFamilyInfo > 0){
              this.isShownErrorMessage=true;
              this.errors.overAllError = 'Please fill the above missing fields.';
              this.validate();
              console.log('test',this.errors);
            } else {
                console.log("2" + this.valid);
                this.errors.primary = 'You must have at least one dependent to qualify for the Head of Household Membership Type.';
            }
        },
        // Custom Validation Rules
        validate() {
            this.errors = {}
            this.valid = false;
            this.overAllError = 1;
            console.log('-------------');
            console.log(this.form);

            var validDiversity = {valid:true}, validEthnicity = {valid:true}, validDisability = {valid:true}, validVeteranStatus = {valid:true};

            const validFirstName = validateFirstName(this.form.first_name, "First Name");
            this.errors.first_name = validFirstName.error;           

            const validLastName = validateLastName(this.form.last_name, "Last Name");
            this.errors.last_name = validLastName.error;           

            const validEmail = validateEmail(this.form.email, "Email");
            this.errors.email = validEmail.error;           

            const validDob = validateDob(this.form.dob, "Dob");
            this.errors.dob = validDob.error;           

            const validGender = validateRadio(this.form.gender, "Gender");
            this.errors.gender = validGender.error;           

            const validDiscipline1 = validateSelect(this.form.discipline_1, "Sport Discipline");
            this.errors.discipline_1 = validDiscipline1.error;

            const validRelation = validateRadio(this.form.relation.toString(), "Relationship");
            this.errors.relation = validRelation.error;

            const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput, "Phone Number");
            this.errors.phone_1 = validPhone1.error;

            const validFederation = validateSelect(this.form.home_federation, "Federation");
            this.errors.home_federation = validFederation.error;

            const validSafeSportForeignFederationId = validateFedration(this.form.skier_license, "");
            this.errors.skier_license = validSafeSportForeignFederationId.error;

            validDiversity = validateRadioBtn(this.form.diversity_id,"Race");
            this.errors.diversity_id = validDiversity.error;

            if(this.subdomain == 'waterski') {
              validEthnicity = validateRadioBtn(this.form.ethnicity, "Ethnicity");
              this.errors.ethnicity = validEthnicity.error;

              validDisability = validateRadioBtn(this.form.disability, "Disability Status");
              this.errors.disability = validDisability.error;

              if(this.form.relation == 1) {
                validVeteranStatus = validateRadioBtn(this.form.veteran_status, "Veteran Status");
                this.errors.veteran_status = validVeteranStatus.error;
              }
            }

          // console.log("Family: >>>", this.form.index, this.familyMembers);

            const validAddress1 = validateName(this.form.address1,"Address");
            this.errors.address1 = validAddress1.error;

            //const validAddress2 = validateName(this.form.address2);
            // this.errors.address2 = validAddress2.error;

            const validCountry = validateSelect(this.form.country,"Country");
            this.errors.country = validCountry.error;

            const validStateCode = validateSelect(this.form.state_code,"State");

            const validState = validateName(this.form.state,"State");

            if(validCountry.valid && this.form.country != 'USA') {
                this.errors.state = validState.error;
            } else {
                this.errors.state_code = validStateCode.error;
            }
            const validCity = validateName(this.form.city,"City");
            this.errors.city = validCity.error;

            // validZip = validateName(this.form.zip,"Postal Code");
            // this.errors.zip = validZip.error;


            const validZip = validateZip(this.form.zip,this.form.country);
            this.errors.zip = validZip.error;
            let spouseFound2 = this.familyMembers.filter(item => item.relation == 1);
            if(this.action == 1 && this.spouseAdded.valid == true && this.form.relation == 1 || this.action == 1 && spouseFound2.length > 0 && this.form.relation == 1) {
                console.log('coming to if');
                this.errors.relation = 'You can add only one spouse';
                this.spouseAdded.current = false;
                validRelation.valid = false;
            } else if(this.action == 2 && this.form.relation == 1 && this.familyMembers[this.form.index].relation != this.form.relation == 1){
                let spouseFound = this.familyMembers.filter(item => item.relation == 1);
                if(spouseFound.length){
                    this.errors.relation = 'You can add only one spouse';
                    this.spouseAdded.current = false;
                    validRelation.valid = false;
                }else{
                  this.spouseAdded.current = true;
                }
            } else {
                this.spouseAdded.current = true;
            }

            if(this.form.relation == 0){
                this.errors.relation = 'Relationship is required.';
                validRelation.valid = false;    
            }

            let dob = this.getAge(this.form.dob);
            // console.log('dob='+dob);
            var range = this.fields.popup.birthDay.min_range || 18;
            var childRange = this.fields.popup.birthDay.child_age || 18;
            if(this.form.relation == 1 && dob < range) {
                // console.log('dob1='+dob);
                // this.errors.dob = 'Spouse age should be greater than ' + range;
                this.errors.dob = 'Spouse age should be '+ range + ' and above';
                validDob.valid = false;
                
            } else if(this.form.relation == 2 && dob >= childRange) {
                // console.log('dob1='+dob);
                this.errors.dob = 'Child age should be less than ' + childRange;
                validDob.valid = false;
            } 

            if(!this.validateDate(this.form.dob)){
                this.errors.dob = 'Invalid Date';
                validDob.valid = false;
            }

            if (this.form.home_federation != 'USA' && !validSafeSportForeignFederationId.valid) {
                this.errors.skier_license = 'Federation ID is required';
            } else if (this.form.home_federation == 'USA') {
                this.form.skier_license = '';
            //   this.valid = true;
            } else {
            //   this.valid = true; 
            }
            if(this.subdomain=='waterski')
            {
              this.errors.address1 = "";
              this.errors.country = "";
              this.errors.state = "";
              this.errors.state_code = "";
              this.errors.city = "";
              this.errors.zip = "";
              validAddress1.valid = true;
              validCountry.valid = true;
              validCity.valid = true;
              validZip.valid = true;
            }
            if (validFirstName.valid && validLastName.valid && validEmail.valid && validDob.valid && validGender.valid
                && validDiscipline1.valid && validRelation.valid
                && validPhone1.valid && this.spouseAdded.current && validFederation.valid && (this.form.home_federation != 'USA' ? validSafeSportForeignFederationId.valid : true) && validDiversity.valid &&  validAddress1.valid  && validCountry.valid && validCity.valid && validZip.valid
                && validEthnicity.valid && validDisability.valid && validVeteranStatus.valid) {
                this.valid = true;
                this.overAllError = 0;                
            }
            // if(Object.keys(this.errors).length == 0) this.valid = false;
            // else this.valid = true;

            // var errorOnly = Object.keys(this.errors).filter(k => this.errors[k] != null);
            // console.log(errorOnly);
            // console.log('true=22=+'+this.valid);
            if(this.form.country == 'USA' && this.subdomain!="waterski") {
              this.form.state = '';
              if(!validStateCode.valid) {
                this.valid = false;
                this.overAllError = 1;
              }
            } else if(!validState.valid && this.form.country == 'Canada' && this.subdomain!="waterski") {
              this.form.state_code = '';
              this.valid = false;
              this.overAllError = 1;
            }
            return this.valid;
        },
        getAge(birth_date) {
            let birth_date_arr = birth_date.split('/');
            console.log(birth_date_arr);
            let today_date = new Date();
            let today_year = today_date.getFullYear();
            let today_month = today_date.getMonth();
            let today_day = today_date.getDate();
            let age = today_year - birth_date_arr[2];

            if ( today_month < (birth_date_arr[0] - 1))
            {
                age--;
            }
            if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1]))
            {
                age--;
            }
            return age;
        },
        isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
        },
        validateTelinput(obj){            
            this.validTelinput = obj.valid;
            if(obj.valid){
                this.errors.phone_1 = '';
            }
        },
        collectDisciplines(){
            this.form.displines_names = [];
            if(Array.isArray(this.form.discipline_1) && this.form.discipline_1.length){
                this.form.discipline_1.forEach(id => {
                    if(this.disciplines[id] != undefined) this.form.displines_names.push(this.disciplines[id]);
                });
            } else {
                if(this.form.discipline_1 != ''){
                    if(this.disciplines[this.form.discipline_1] != undefined)
                        this.form.displines_names.push(this.disciplines[this.form.discipline_1]);
                }
            }
        },
        collectSecondChoice(){
            this.form.secondChoiceNames = [];
            if(this.form.discipline_2 != ''){
                if(this.disciplines[this.form.discipline_2] != undefined)
                    this.form.secondChoiceNames.push(this.disciplines[this.form.discipline_2]);
            }
        },
        removeHohEmail(relation) {
          if(relation == 1) {
            this.form.email = '';
          }
        },
        saveDiversityTxt(txt){
            this.form.diversity_txt = txt;
        },
        removeStateCode(){
          this.form.state = '';
          this.form.state_code = '';
        },
        messageFor(val){
            this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
      },
      checkFamilyMemberDemographic(item,valid = true){
          if(valid && (this.subdomain=='waterski' && this.isValidFamilyInfo !=0 && (item.diversity_id != 99 || item.diversity_id != '' && item.diversity_id && item.ethnicity && item.veteran_status && item.disability)) || (this.subdomain=='shooting' && this.isValidFamilyInfo !=0 && item.diversity_id)){
            this.isValidFamilyInfo = this.isValidFamilyInfo-1;
            item.error_message = "";
          }else if(this.isValidFamilyInfo != 0){
            this.isValidFamilyInfo = this.isValidFamilyInfo-1;
            this.familyMembers[item].error_message = "";
          }
      }
    },
    mounted() {
        // this.form.index = this.members.length;
        // console.log('this form index=' + this.form.index);
        // // this.familyMembers = this.members;
        // console.log('this family members');
        // console.log(this.members);
      this.getClubs();

        const retry = setInterval(() => {
            if(this.members[0].first_name){
                clearInterval(retry);
                if(Array.isArray(this.members) && this.members.length){
                    let index = 0;
                    for(let item of this.members){
                        if(item.relation != 0){
                            let discipline_1 = [];
                            let discipline_2 = "";
                            let disciplines_names = [];
                            let secondChoiceNames = [];
                            let error_message = "";

                            if(item.member != undefined && item.member.disciplines != undefined && item.member.disciplines.length){
                                for(let dis of item.member.disciplines){
                                    discipline_1.push(dis.id);
                                    disciplines_names.push(dis.name);
                                }
                            } else {
                                discipline_1 = item.first_choice;
                                discipline_2 = item.second_choice;
                                disciplines_names.push(item.first_choice_txt);
                                secondChoiceNames.push(item.second_choice_txt);
                            }
                          if((this.subdomain=='waterski' && (item.diversity_id == 99 || item.diversity_id == '' || !item.diversity_id || !item.ethnicity || !item.disability || (!item.veteran_status && item.relation==1))) || (this.subdomain=='shooting' && (item.diversity_id == 99 || item.diversity_id == ''))){
                            this.isValidFamilyInfo = this.isValidFamilyInfo+1;
                            var error_field = [];
                            let field = " field.";
                            if(this.subdomain=='waterski'){
                              if(item.diversity_id == 99 || item.diversity_id == '' || !item.diversity_id)
                                error_field.push('Race');
                              if(!item.ethnicity)
                                error_field.push('Ethnicity');
                              if(!item.disability)
                                error_field.push('Disability Status');
                              if(!item.veteran_status && item.relation==1)
                                error_field.push('Veteran Status');
                              field = error_field.length>0?' fields.':' field.';
                              error_message = "Please fill the missing"+field+" - "+error_field.map(element => `'${element}'`).join(', ');
                            }
                            else{
                              if(item.diversity_id == 99 || item.diversity_id == '')
                                error_message = "Please fill the missing field - 'Ethnicity'";
                            }
                          }
                          console.log("data",this.isValidFamilyInfo);
                          let clubandteam = [];
                          if(Object.keys(item.member.affiliated_clubs_dropdown).length > 0)
                            clubandteam = Object.keys(item.member.affiliated_clubs_dropdown);
                            this.familyMembers.push({
                                current_member_id: item.member_id,
                                index: index,
                                citizenship: "",
                                multiple_affiliates_arr: clubandteam,
                                disability: item.disability,
                                discipline_1: discipline_1,
                                discipline_2: discipline_2,
                                displines_names: disciplines_names,
                                diversity_id: (item.diversity_id == 99)?null:item.diversity_id,
                                diversity_txt: item.diversity_txt,
                                dob: item.dob,
                                email: item.contact_details.email,
                                ethnicity: item.ethnicity,
                                first_name: item.first_name,
                                gender: item.gender,
                                home_federation: item.home_federation == null ? 'USA' : item.home_federation,
                                last_name: item.last_name,
                                middle_name: item.middle_name,
                                phone_1: item.contact_details.phone_1,
                                phone_2: item.contact_details.phone_2,
                                relation: item.relation,
                                secondChoiceNames: secondChoiceNames,
                                skier_license: item.skier_license,
                                veteran_status: item.veteran_status,
                                address1: item.contact_details.address1,
                                address2: item.contact_details.address2,
                                zip: item.contact_details.zip,
                                state_code: item.contact_details.state_code,
                                state: item.contact_details.state,
                                city: item.contact_details.city,
                                country: item.contact_details.country,
                                error_message: error_message,
                            });
                            index = index + 1;
                          //Is child detached detected
                          if(item.relation == 2 && this.isChildDetach(item.dob, this.next_renewal_period.start_date)) this.childDetachDetected = true;
                        }
                    }
                }
            }
        }, 200);
    },
    updated() {
        // this.disciplines_list = this.disciplines.m
        let discipline_codes = Object.keys(this.disciplines);
        if(discipline_codes.length && this.disciplines_list.length == 0){
            discipline_codes.forEach(code => {
                this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
            });
        }
    },
    filters: {
        joinArray(array){
            return array.join(', ');
        }
    },
    computed: {
        first_choice_class: function(){
            return this.fields.popup && this.fields.popup.sport_discipline && this.fields.popup.sport_discipline.first_choice.multiple ? 'col-md-12' : 'col-md-6';
        },
        childValidAge: function(){
            let childAgeVal = this.getSiteProps('memberRegistration.family.fields.family.popup.birthDay.child_age');
            return childAgeVal - 1;
        },
        childDetachMessage: function() {
        let msg = this.getSiteProps('familyMembersRenewal.childDetachMessage');
        return (msg.includes("{{ childValidAge }}") ? msg.replace("{{ childValidAge }}", this.childValidAge) : msg);
      }
    }
}
</script>

<style scoped>

</style>
